import Image from "next/image";
import BtmCtaEn from "@public/btm-cta-en.png";
import BtmCtaBm from "@public/btm-cta-bm.png";
import BtmCtaZh from "@public/btm-cta-zh.png";
import DownloadNowImage from "@components/core/download-now-image";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";

const BottomCta: React.FunctionComponent = ({}) => {
  const { locale } = useRouter();

  const screenshot = {
    en: BtmCtaEn,
    ms: BtmCtaBm,
    zh: BtmCtaZh,
  };

  return <div className="py-2"></div>;

  return (
    <div className="relative pt-80">
      <div className="absolute inset-x-0 top-16 z-10 flex justify-center">
        <Image
          src={screenshot[locale]}
          alt="lumi screenshot"
          width={350}
          height={640}
        />
      </div>

      <div className="bg-primary2 px-8 pb-10 pt-[440px]">
        <div className="mx-auto max-w-sm">
          <p className="text-center font-poppins text-2xl font-bold text-white">
            <Trans>An easier way to get news and share it 🔥</Trans>
          </p>

          <div className="mt-8 px-8">
            <div className="flex items-center">
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white">
                ☕
              </div>
              <div className="ml-4 flex-1">
                <p className="font-poppins text-light-background">
                  <Trans>Get the latest COVID-19 news</Trans>
                </p>
              </div>
            </div>
            <div className="mt-4 flex items-center">
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white">
                🔔
              </div>
              <div className="ml-4 flex-1">
                <p className="font-poppins text-light-background">
                  <Trans>Share with your family & friends</Trans>
                </p>
              </div>
            </div>
            <div className="mt-4 flex items-center">
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white">
                ✅
              </div>
              <div className="ml-4 flex-1">
                <p className="font-poppins text-light-background">
                  <Trans>Know when something trends</Trans>
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8 text-center">
            <DownloadNowImage
              locale={locale}
              eventName={"Download Button CTA"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomCta;
