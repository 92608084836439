import { useEffect } from "react";
import Router, { NextRouter } from "next/router";

// this ts file is to retain previously scrolled content, this might not work as intended as user journey may or may not be complicated. another method is to enable in built nextjs scrollRestoration in config file (still experimental).

function saveScrollPos(asPath: string, refValue?: any) {
  sessionStorage.setItem(
    `scrollPos:${asPath}`,
    JSON.stringify({ x: window.scrollX, y: window.scrollY })
  );
  if (refValue && refValue?.isIntersecting) {
    sessionStorage.setItem(
      `intersectedScrollPos:${asPath}`,
      JSON.stringify(refValue?.boundingClientRect.top)
    );
  } else {
    sessionStorage.removeItem(`intersectedScrollPos:${asPath}`);
  }
}

function restoreScrollPos(asPath: string, retainWholePage: boolean) {
  const json = sessionStorage.getItem(`scrollPos:${asPath}`);
  const intersectedScrollPos = sessionStorage.getItem(
    `intersectedScrollPos:${asPath}`
  );

  const scrollPos = json ? JSON.parse(json) : undefined;

  if (
    (scrollPos && retainWholePage) ||
    (scrollPos &&
      intersectedScrollPos != null &&
      scrollPos.y >= intersectedScrollPos)
  ) {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    (async () => {
      while (window.scrollY <= scrollPos.y) {
        window.scrollTo({
          left: scrollPos.x,
          top: scrollPos.y,
          // behavior: "smooth",
        });
        await delay(300);
        if (window.scrollY == scrollPos.y) break;
      }
    })();
  }
}

export function useScrollRestoration(
  router: NextRouter,
  retainWholePage: boolean,
  refValue?: any
) {
  useEffect(() => {
    if (!("scrollRestoration" in window.history)) return;
    let shouldScrollRestore = false;
    window.history.scrollRestoration = "manual";

    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      saveScrollPos(router.asPath, refValue);
      delete event["returnValue"];
    };

    const onRouteChangeStart = () => {
      saveScrollPos(router.asPath, refValue);
    };

    const onRouteChangeComplete = (url: string) => {
      restoreScrollPos(router.asPath, retainWholePage);
      if (shouldScrollRestore) {
        shouldScrollRestore = false;
        /**
         * Calling with relative url, not expected asPath, so this
         * will break if there is a basePath or locale path prefix.
         */
        restoreScrollPos(url, retainWholePage);
      }
    };

    window.addEventListener("beforeunload", onBeforeUnload);
    Router.events.on("routeChangeStart", onRouteChangeStart);
    Router.events.on("routeChangeComplete", onRouteChangeComplete);
    Router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        shouldScrollRestore = true;
      }
      return true;
    });

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      Router.events.off("routeChangeStart", onRouteChangeStart);
      Router.events.off("routeChangeComplete", onRouteChangeComplete);
      Router.beforePopState(() => true);
    };
  }, [router, refValue]);
}
