import MagnifyingGlassIcon from "@heroicons/react/20/solid/MagnifyingGlassIcon";
import { useCustomBannerQuery } from "@graphql/generated/graphql";
import { Dialog, Transition } from "@headlessui/react";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { showSearchBarAtom } from "./layout";
import { generateDomainPath } from "./shared/link-props";

interface FocusInput {
  setFocusInput?: React.Dispatch<React.SetStateAction<boolean>>;
  focusInput?: boolean;
  showNewTab: boolean;
}

const HeaderSearch: React.FunctionComponent<FocusInput> = ({
  setFocusInput,
  focusInput,
  showNewTab,
}) => {
  const router = useRouter();
  const { locale, query } = router;
  const { data } = useCustomBannerQuery({
    variables: {
      languageCode: locale,
    },
  });
  const [showSearchBar, setShowSearchBar] = useAtom(showSearchBarAtom);
  const searchPlaceholder = data?.customBanner.searchBarHintText || "";

  return (
    <div>
      <div>
        <MagnifyingGlassIcon
          className="h-5 w-5 cursor-pointer text-primary2"
          aria-hidden="true"
          onClick={() => {
            query.search
              ? setFocusInput(!focusInput)
              : setShowSearchBar(!showSearchBar);
          }}
        />
      </div>

      <Transition show={showSearchBar} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          style={{
            filter: "drop-shadow(1px 8px 16px rgba(147, 154, 188, 0.5)",
          }}
          onClose={() => {
            setShowSearchBar(false);
          }}
        >
          <div className="mx-8 min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-light-background bg-opacity-20 transition-opacity" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="mt-32 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl bg-off-white py-4 align-top shadow-xl transition-all">
                <form
                  action={generateDomainPath(`/${locale}/explore`)}
                  target={showNewTab ? "_blank" : undefined}
                  method="get"
                  className="w-full appearance-none bg-off-white font-sen text-text-darkest"
                >
                  <div className="space-between mx-4 flex flex-row md:mx-8">
                    <input
                      className="w-full appearance-none bg-off-white font-sen text-sm text-text-darkest md:text-lg"
                      type="text"
                      name="search"
                      placeholder={searchPlaceholder}
                    />
                    <button type="submit">
                      <MagnifyingGlassIcon
                        className="h-5 w-5 cursor-pointer text-primary3"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
export default HeaderSearch;
