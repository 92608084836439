import Footer from "@components/layout/footer";
import BottomCta from "@components/layout/bottom-cta";
import Header from "@components/layout/header";
import { atom } from "jotai";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useScrollRestoration } from "@lib/useScrollRestoration";

export const showSearchBarAtom = atom(false);

interface ILayoutProps {
  showNewsStacks?: boolean;
  className?: string;
  focusInput?: boolean;
  setFocusInput?: React.Dispatch<React.SetStateAction<boolean>>;
  showFooter?: boolean;
  showCTA?: boolean;
  hideHeader?: boolean;
  hideMagnifyingGlassIcon?: boolean;
  retainWholePage?: boolean;
  refValue?: IntersectionObserverEntry;
}

const Layout: React.FunctionComponent<ILayoutProps> = ({
  showNewsStacks,
  children,
  className = "",
  setFocusInput,
  focusInput,
  showFooter = true,
  showCTA = true,
  hideMagnifyingGlassIcon = false,
  retainWholePage,
  refValue,
}) => {
  const [hideHeader, setHideHeader] = useState(false);
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (query.m === "true") {
      setHideHeader(true);
    }
  }, [query]);

  useScrollRestoration(router, retainWholePage, refValue);

  return (
    <div className={`min-h-screen overflow-hidden ${className}`}>
      {!hideHeader ? (
        <Header
          showNewsStacks={showNewsStacks}
          setFocusInput={setFocusInput}
          focusInput={focusInput}
          hideMagnifyingGlassIcon={hideMagnifyingGlassIcon}
        />
      ) : (
        <div className="h-[138px]"></div>
      )}

      <main className="flex-1">{children}</main>

      {showCTA && (
        <div className="mt-10">
          <BottomCta />
        </div>
      )}

      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;
