import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import Link from "next/link";
import { Fragment } from "react";
import { useRouter } from "next/router";
import { Menu, Transition } from "@headlessui/react";

type LOCALES = "en" | "zh" | "ms";

const LANGUAGES: { key: LOCALES; value: string }[] = [
  {
    key: "en",
    value: "ENG",
  },
  {
    key: "ms",
    value: "BM",
  },
  {
    key: "zh",
    value: "中文",
  },
];

const LocaleOption: React.FC<{}> = () => {
  return (
    <div>
      <div className="hidden lg:block">
        <WideLocaleOption />
      </div>
      <div className="lg:hidden">
        <DropdownLocaleOption />
      </div>
    </div>
  );
};

const WideLocaleOption: React.FC<{}> = () => {
  const router = useRouter();
  let { pathname, query, locale } = router;

  pathname = pathname.startsWith("/_subdomain")
    ? `/${pathname.split("/").splice(3).join("/")}`
    : pathname;

  return (
    <div className="hidden space-x-2 font-poppins text-text-dark lg:flex">
      {LANGUAGES.map(({ key, value }) => (
        <Link href={{ pathname, query }} passHref locale={key} key={key}>
          <a
            key={key}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              window.location.replace(e.currentTarget.href);
            }}
            className={`font-poppins ${
              locale === key
                ? "font-bold text-primary2"
                : "cursor-pointer hover:font-bold"
            }`}
          >
            {value}
          </a>
        </Link>
      ))}
    </div>
  );
};

const DropdownLocaleOption: React.FC<{}> = () => {
  const router = useRouter();
  let { pathname, query, locale } = router;

  pathname = pathname.startsWith("/_subdomain")
    ? `/${pathname.split("/").splice(3).join("/")}`
    : pathname;

  return (
    <Menu as="div" className="relative inline-block text-left lg:hidden">
      {({ open }) => (
        <>
          <div className="flex items-center">
            <Menu.Button className="flex h-8 w-14 items-center">
              <p className="text-sm font-bold text-primary2">
                {LANGUAGES.find((language) => language.key === locale).value}
              </p>
              <div>
                <ChevronDownIcon className="h-5 w-5 cursor-pointer text-primary2" />
              </div>
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-30 mt-2 w-32 origin-top-right rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="pb-4 pt-2">
                {LANGUAGES.map(({ key, value }) => (
                  <Menu.Item key={key}>
                    <Link
                      href={{ pathname, query }}
                      passHref
                      locale={key}
                      key={key}
                    >
                      <a
                        key={key}
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                          e.preventDefault();
                          window.location.replace(e.currentTarget.href);
                        }}
                        className={`mx-4 mt-2 block rounded-xl bg-off-white p-2 font-sen ${
                          locale === key
                            ? "font-bold text-primary2"
                            : "cursor-pointer text-text-medium"
                        }`}
                      >
                        {value}
                      </a>
                    </Link>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default LocaleOption;
