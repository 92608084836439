import MagnifyingGlassIcon from "@heroicons/react/20/solid/MagnifyingGlassIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useCustomBannerQuery } from "@graphql/generated/graphql";

interface FocusInput {
  focusInput?: boolean;
}

const SearchBar: React.FunctionComponent<FocusInput> = ({ focusInput }) => {
  const router = useRouter();
  const { query, locale } = router;
  const { data } = useCustomBannerQuery({
    variables: {
      languageCode: locale,
    },
  });
  const [searchQuery, setSearchQuery] = useState(query.search);
  const searchPlaceholder = data?.customBanner.searchBarHintText || "";
  const inputReference = useRef(null);

  useEffect(() => {
    if (!query.search) {
      setSearchQuery("");
    } else {
      setSearchQuery(query.search);
    }
  }, [query.search]);

  useEffect(() => {
    query.search && !searchQuery && inputReference.current.focus();
  }, [focusInput]);

  return (
    <div className="h-[56px] w-full max-w-4xl rounded-2xl bg-off-white py-4 align-top">
      <form
        action={`/${locale}/explore`}
        method="get"
        className="w-full appearance-none bg-off-white font-sen text-text-darkest"
      >
        <div className="space-between mx-4 flex flex-row md:mx-8">
          <input
            className="w-full appearance-none bg-off-white font-sen text-text-darkest md:text-lg"
            ref={inputReference}
            type="text"
            name="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            tabIndex={-1}
            placeholder={searchPlaceholder}
          />
          {searchQuery === "" ? (
            <button type="submit">
              <MagnifyingGlassIcon
                className="h-5 w-5 cursor-pointer text-primary3"
                aria-hidden="true"
              />
            </button>
          ) : (
            <button type="submit">
              <XMarkIcon
                className="h-5 w-5 cursor-pointer text-primary3"
                aria-hidden="true"
                onClick={(e) => {
                  e.preventDefault();
                  setSearchQuery("");
                }}
              />
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
