import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  /** String that convert to current user locale */
  LocaleString: any;
};

export type AppBanner = {
  __typename?: 'AppBanner';
  backgroundColor?: Maybe<Scalars['String']>;
  buttonBackgroundColor?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['LocaleString']>;
  buttonTextColor?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['LocaleString']>;
  dismissable: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  title: Scalars['LocaleString'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum AppBannerThemeEnum {
  Light = 'light',
  Dark = 'dark'
}

export type Author = {
  __typename?: 'Author';
  authorType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};

/** Autogenerated return type of BindAccountConfirm */
export type BindAccountConfirmPayload = {
  __typename?: 'BindAccountConfirmPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of BindAccount */
export type BindAccountPayload = {
  __typename?: 'BindAccountPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of BindOauthToUser */
export type BindOauthToUserPayload = {
  __typename?: 'BindOauthToUserPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of BindPhoneAccountToOauth */
export type BindPhoneAccountToOauthPayload = {
  __typename?: 'BindPhoneAccountToOauthPayload';
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
};

/** Autogenerated return type of BindPhoneAccountToUser */
export type BindPhoneAccountToUserPayload = {
  __typename?: 'BindPhoneAccountToUserPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

export type BookmarkContentInput = {
  contentId: Scalars['ID'];
  contentType: BookmarkTypeEnum;
};

/** Autogenerated return type of BookmarkContent */
export type BookmarkContentPayload = {
  __typename?: 'BookmarkContentPayload';
  content?: Maybe<Content>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated return type of BookmarkNews */
export type BookmarkNewsPayload = {
  __typename?: 'BookmarkNewsPayload';
  errors?: Maybe<Array<Error>>;
  news?: Maybe<News>;
};

export enum BookmarkTypeEnum {
  News = 'News',
  Video = 'Video',
  Short = 'Short'
}

export type Campaign = {
  __typename?: 'Campaign';
  bannerImageUrl?: Maybe<Scalars['String']>;
  bannerSubtitle?: Maybe<Scalars['LocaleString']>;
  bannerTitle?: Maybe<Scalars['LocaleString']>;
  description?: Maybe<Scalars['LocaleString']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  shareCopy?: Maybe<Scalars['LocaleString']>;
  subtitle?: Maybe<Scalars['LocaleString']>;
  title?: Maybe<Scalars['LocaleString']>;
};

export type CarouselContent = {
  __typename?: 'CarouselContent';
  contentable?: Maybe<Contentable>;
  id: Scalars['ID'];
  sponsored: Scalars['Boolean'];
};

export type CarouselContentNews = CarouselContent | News;

export type Category = {
  __typename?: 'Category';
  author?: Maybe<Author>;
  comments: Array<Comment>;
  commentsCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['LocaleString']>;
  emoji?: Maybe<Scalars['String']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  exploreName: Scalars['String'];
  favourite?: Maybe<Scalars['Boolean']>;
  hasTopicBlocks: Scalars['Boolean'];
  hot?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isAdultNonMuslimContent: Scalars['Boolean'];
  key: Scalars['String'];
  lastPublishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  like: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  name: Scalars['String'];
  newStatus?: Maybe<Scalars['Boolean']>;
  news?: Maybe<Array<News>>;
  newsCount: Scalars['Int'];
  order: Scalars['Int'];
  publishersCount: Scalars['Int'];
  richContent?: Maybe<RichContent>;
  shareCount: Scalars['Int'];
  slug: Scalars['String'];
  socialImageUrl?: Maybe<Scalars['String']>;
  suggestedCategoryIds: Array<Scalars['ID']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
  webTitle: Scalars['String'];
};


export type CategoryAuthorArgs = {
  languageCode?: Maybe<Scalars['String']>;
};


export type CategoryCommentsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type CategoryNewsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type CategoryRichContentArgs = {
  languageCode?: Maybe<Scalars['String']>;
};


export type CategorySuggestedCategoryIdsArgs = {
  languageCode?: Maybe<Scalars['String']>;
};

export enum ChatEnum {
  SeeAll = 'seeAll',
  Disable = 'disable'
}

export type Comment = {
  __typename?: 'Comment';
  commentable?: Maybe<Commentable>;
  commentableId?: Maybe<Scalars['ID']>;
  commentableType?: Maybe<CommentTypeEnum>;
  commentsCount: Scalars['Int'];
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  like: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  topReplies?: Maybe<Array<Comment>>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: UserPublic;
};

/** Autogenerated return type of CommentContent */
export type CommentContentPayload = {
  __typename?: 'CommentContentPayload';
  comment?: Maybe<Comment>;
  commentable?: Maybe<Commentable>;
  commentsCount?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum CommentTypeEnum {
  News = 'News',
  Video = 'Video',
  Category = 'Category',
  Comment = 'Comment'
}

export type Commentable = Category | News | Video;

export type Content = News | Video;

export type ContentCommentsCount = {
  __typename?: 'ContentCommentsCount';
  count: Scalars['Int'];
};

export type ContentWithTopComments = {
  __typename?: 'ContentWithTopComments';
  comments: Array<Comment>;
  content?: Maybe<Commentable>;
};

export type Contentable = Category | News | Video;

export type CovidStat = {
  __typename?: 'CovidStat';
  activeCases: Scalars['Int'];
  cat1?: Maybe<Scalars['Int']>;
  cat1Percentage?: Maybe<Scalars['Float']>;
  cat2?: Maybe<Scalars['Int']>;
  cat2Percentage?: Maybe<Scalars['Float']>;
  cat3?: Maybe<Scalars['Int']>;
  cat3Percentage?: Maybe<Scalars['Float']>;
  cat4?: Maybe<Scalars['Int']>;
  cat4Percentage?: Maybe<Scalars['Float']>;
  cat5?: Maybe<Scalars['Int']>;
  cat5Percentage?: Maybe<Scalars['Float']>;
  date: Scalars['ISO8601DateTime'];
  deaths: Scalars['Int'];
  detailsDate: Scalars['ISO8601DateTime'];
  hospitalUsagePercentage: Scalars['Float'];
  icuUsagePercentage: Scalars['Float'];
  id: Scalars['ID'];
  newCases: Scalars['Int'];
  recovered: Scalars['Int'];
  statDate: Scalars['ISO8601Date'];
  stateData: Array<StateCovidStat>;
  todayActive: Scalars['Int'];
  totalCases: Scalars['Int'];
  totalDeaths: Scalars['Int'];
  totalRecovered: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of CreateUserContentFeedback */
export type CreateUserContentFeedbackPayload = {
  __typename?: 'CreateUserContentFeedbackPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateUserNewsPublisherFeedback */
export type CreateUserNewsPublisherFeedbackPayload = {
  __typename?: 'CreateUserNewsPublisherFeedbackPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CustomBanner = {
  __typename?: 'CustomBanner';
  activeCasesLabel?: Maybe<Scalars['LocaleString']>;
  communityBannerColor?: Maybe<Scalars['LocaleString']>;
  communityBannerImage?: Maybe<Scalars['LocaleString']>;
  communityBannerLink?: Maybe<Scalars['LocaleString']>;
  communityBannerSubtitle?: Maybe<Scalars['LocaleString']>;
  communityBannerTitle?: Maybe<Scalars['LocaleString']>;
  confirmedCasesLabel?: Maybe<Scalars['LocaleString']>;
  covidTabBanner?: Maybe<Scalars['LocaleString']>;
  customLatestTabBanner?: Maybe<Scalars['LocaleString']>;
  customLatestTabBannerColor?: Maybe<Scalars['LocaleString']>;
  customLatestTabBannerLink?: Maybe<Scalars['LocaleString']>;
  dailyDosesLabel?: Maybe<Scalars['LocaleString']>;
  fatalitiesCasesLabel?: Maybe<Scalars['LocaleString']>;
  goodNewsBanner?: Maybe<Scalars['LocaleString']>;
  id?: Maybe<Scalars['ID']>;
  latestTabBanner?: Maybe<Scalars['LocaleString']>;
  newCovidCasesLabel?: Maybe<Scalars['LocaleString']>;
  oneDoseLabel?: Maybe<Scalars['LocaleString']>;
  recoveredCasesLabel?: Maybe<Scalars['LocaleString']>;
  searchBarHintText?: Maybe<Scalars['LocaleString']>;
  stateCasesLabel?: Maybe<Scalars['LocaleString']>;
  totalDosesLabel?: Maybe<Scalars['LocaleString']>;
  twoDosesLabel?: Maybe<Scalars['LocaleString']>;
  vaccinationGoalLabel?: Maybe<Scalars['LocaleString']>;
};

export type CustomNotification = {
  __typename?: 'CustomNotification';
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CustomStoryContent = {
  __typename?: 'CustomStoryContent';
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** Autogenerated return type of CustomizeFavouriteCategories */
export type CustomizeFavouriteCategoriesPayload = {
  __typename?: 'CustomizeFavouriteCategoriesPayload';
  errors?: Maybe<Array<Error>>;
  followedCategories?: Maybe<Array<Category>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of DeleteAccountRequest */
export type DeleteAccountRequestPayload = {
  __typename?: 'DeleteAccountRequestPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteComment */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  commentable?: Maybe<Commentable>;
  commentsCount?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Error = {
  __typename?: 'Error';
  detail: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of FavouriteCategory */
export type FavouriteCategoryPayload = {
  __typename?: 'FavouriteCategoryPayload';
  category?: Maybe<Category>;
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

export type FeedBlock = {
  __typename?: 'FeedBlock';
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** Autogenerated return type of FollowNewsPublisher */
export type FollowNewsPublisherPayload = {
  __typename?: 'FollowNewsPublisherPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
  newsPublisher?: Maybe<NewsPublisher>;
};

export type FypFeedContent = Category | FeedBlock | News | Video;

export type FypFeedLastUpdateAt = {
  __typename?: 'FypFeedLastUpdateAt';
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type FypHeroCardContent = Category | CustomStoryContent | News | Video;

export type Ge15Stack = {
  __typename?: 'Ge15Stack';
  categories: Array<Category>;
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type Ge15Status = {
  __typename?: 'Ge15Status';
  active: Scalars['Boolean'];
};

export enum HomeFeedModeEnum {
  Fyp = 'fyp',
  Latest = 'latest'
}




export type Language = {
  __typename?: 'Language';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  newsPublishers: Array<NewsPublisher>;
};

export type LatestTabFeedContent = Category | FeedBlock | News | Video;

export type LatestTabHeroCardContent = Category | CustomStoryContent | News | Video;

export enum LatestTabViewEnum {
  Timeline = 'timeline',
  Category = 'category',
  ForYou = 'forYou'
}

/** Autogenerated return type of LikeContent */
export type LikeContentPayload = {
  __typename?: 'LikeContentPayload';
  errors?: Maybe<Array<Error>>;
  like?: Maybe<Scalars['Boolean']>;
  likesCount?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of LikeNews */
export type LikeNewsPayload = {
  __typename?: 'LikeNewsPayload';
  errors?: Maybe<Array<Error>>;
  news?: Maybe<News>;
};

export enum LikeTypeEnum {
  News = 'News',
  Video = 'Video',
  Category = 'Category',
  Comment = 'Comment'
}


export type LoginInput = {
  userId: Scalars['ID'];
  phoneCode: Scalars['String'];
  deviceToken?: Maybe<Scalars['String']>;
  deviceTokenType?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Login */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
};

/** Autogenerated return type of Logout */
export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of MassFavouriteCategories */
export type MassFavouriteCategoriesPayload = {
  __typename?: 'MassFavouriteCategoriesPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of MassFollowNewsPublisher */
export type MassFollowNewsPublisherPayload = {
  __typename?: 'MassFollowNewsPublisherPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  bindAccount?: Maybe<BindAccountPayload>;
  bindAccountConfirm?: Maybe<BindAccountConfirmPayload>;
  /** Binds social login details to the current user */
  bindOauthToUser?: Maybe<BindOauthToUserPayload>;
  /** Binds social login to an existing user identified by phone number */
  bindPhoneAccountToOauth?: Maybe<BindPhoneAccountToOauthPayload>;
  /** Binds phone user to the current user (should be a social login user) */
  bindPhoneAccountToUser?: Maybe<BindPhoneAccountToUserPayload>;
  bookmarkContent?: Maybe<BookmarkContentPayload>;
  bookmarkNews?: Maybe<BookmarkNewsPayload>;
  commentContent?: Maybe<CommentContentPayload>;
  createUserContentFeedback?: Maybe<CreateUserContentFeedbackPayload>;
  createUserNewsPublisherFeedback?: Maybe<CreateUserNewsPublisherFeedbackPayload>;
  /** Takes a list of category IDs and configure user's favourite categories based on it. New ID(s) not in the database will be added to database, ID(s) in the database that are not in the list will be removed, and the order of user's favourite categories will be configured according to the given list. */
  customizeFavouriteCategories?: Maybe<CustomizeFavouriteCategoriesPayload>;
  deleteAccountRequest?: Maybe<DeleteAccountRequestPayload>;
  deleteComment?: Maybe<DeleteCommentPayload>;
  favouriteCategory?: Maybe<FavouriteCategoryPayload>;
  followNewsPublisher?: Maybe<FollowNewsPublisherPayload>;
  likeContent?: Maybe<LikeContentPayload>;
  likeNews?: Maybe<LikeNewsPayload>;
  login?: Maybe<LoginPayload>;
  logout?: Maybe<LogoutPayload>;
  massFavouriteCategories?: Maybe<MassFavouriteCategoriesPayload>;
  massFollowNewsPublisher?: Maybe<MassFollowNewsPublisherPayload>;
  onboardUser?: Maybe<OnboardUserPayload>;
  readNotification?: Maybe<ReadNotificationPayload>;
  readSocialNotification?: Maybe<ReadSocialNotificationPayload>;
  replyComment?: Maybe<ReplyCommentPayload>;
  reportChatMessage?: Maybe<ReportChatMessagePayload>;
  reportComment?: Maybe<ReportCommentPayload>;
  requestPhoneCode?: Maybe<RequestPhoneCodePayload>;
  resendOtp?: Maybe<ResendOtpPayload>;
  shareContent?: Maybe<ShareContentPayload>;
  signup?: Maybe<SignupPayload>;
  signupConfirm?: Maybe<SignupConfirmPayload>;
  signupGuest?: Maybe<SignupGuestPayload>;
  signupOauth?: Maybe<SignupOauthPayload>;
  signupWithUid?: Maybe<SignupWithUidPayload>;
  submitAppReview?: Maybe<SubmitAppReviewPayload>;
  subscribeGoodNews?: Maybe<SubscribeGoodNewsPayload>;
  subscribeGoodNewsNotification?: Maybe<SubscribeGoodNewsNotificationPayload>;
  updateCustomNotification?: Maybe<UpdateCustomNotificationPayload>;
  updateDeviceToken?: Maybe<UpdateDeviceTokenPayload>;
  updateDeviceVersion?: Maybe<UpdateDeviceVersionPayload>;
  updateInitialPrimaryLanguage?: Maybe<UpdateInitialPrimaryLanguagePayload>;
  updateNotificationPreference?: Maybe<UpdatePayload>;
  updatePrimaryLanguage?: Maybe<UpdatePrimaryLanguagePayload>;
  updatePrnNotificationPreference?: Maybe<UpdatePrn2023NotificationPreferencePayload>;
  updateUserPreference?: Maybe<UpdateUserPreferencePayload>;
  updateUserProfile?: Maybe<UpdateUserProfilePayload>;
  viewVideo?: Maybe<ViewVideoPayload>;
};


export type MutationBindAccountArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationBindAccountConfirmArgs = {
  phoneCode: Scalars['String'];
};


export type MutationBindOauthToUserArgs = {
  provider: Scalars['String'];
  uid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationBindPhoneAccountToOauthArgs = {
  phoneNumber: Scalars['String'];
  deviceUid: Scalars['String'];
  deviceType: Scalars['String'];
  provider: Scalars['String'];
  uid: Scalars['String'];
  deviceToken?: Maybe<Scalars['String']>;
  deviceTokenType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationBindPhoneAccountToUserArgs = {
  userId: Scalars['ID'];
  phoneCode: Scalars['ID'];
};


export type MutationBookmarkContentArgs = {
  input: BookmarkContentInput;
};


export type MutationBookmarkNewsArgs = {
  newsId: Scalars['ID'];
};


export type MutationCommentContentArgs = {
  comment: Scalars['String'];
  contentId: Scalars['ID'];
  contentType: CommentTypeEnum;
};


export type MutationCreateUserContentFeedbackArgs = {
  contentId: Scalars['ID'];
  contentType: UserContentFeedbackContentTypeEnum;
  sentiment: UserContentFeedbackSentimentEnum;
};


export type MutationCreateUserNewsPublisherFeedbackArgs = {
  newsPublisherId: Scalars['ID'];
  contentId: Scalars['ID'];
  contentType: UserContentFeedbackContentTypeEnum;
  sentiment: UserNewsPublisherFeedbackSentimentEnum;
};


export type MutationCustomizeFavouriteCategoriesArgs = {
  categoryIds: Array<Scalars['ID']>;
};


export type MutationDeleteAccountRequestArgs = {
  reason?: Maybe<Scalars['String']>;
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


export type MutationFavouriteCategoryArgs = {
  categoryId: Scalars['ID'];
};


export type MutationFollowNewsPublisherArgs = {
  newsPublisherId: Scalars['ID'];
};


export type MutationLikeContentArgs = {
  contentId: Scalars['ID'];
  contentType: LikeTypeEnum;
};


export type MutationLikeNewsArgs = {
  newsId: Scalars['ID'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLogoutArgs = {
  deviceToken?: Maybe<Scalars['String']>;
};


export type MutationMassFavouriteCategoriesArgs = {
  categoryIds: Array<Scalars['ID']>;
};


export type MutationMassFollowNewsPublisherArgs = {
  newsPublisherIds: Array<Scalars['ID']>;
};


export type MutationOnboardUserArgs = {
  favouriteCategoryIds: Array<Scalars['ID']>;
  followedNewsPublisherIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationReadNotificationArgs = {
  notificationId: Scalars['ID'];
};


export type MutationReadSocialNotificationArgs = {
  notificationId: Scalars['ID'];
};


export type MutationReplyCommentArgs = {
  replyContent: Scalars['String'];
  commentId: Scalars['ID'];
};


export type MutationReportChatMessageArgs = {
  username?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  chatRoomId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationReportCommentArgs = {
  commentId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};


export type MutationRequestPhoneCodeArgs = {
  input: RequestPhoneCodeInput;
};


export type MutationResendOtpArgs = {
  userId: Scalars['ID'];
};


export type MutationShareContentArgs = {
  contentId: Scalars['ID'];
  contentType: ShareTypeEnum;
};


export type MutationSignupArgs = {
  input: SignupInput;
};


export type MutationSignupConfirmArgs = {
  input: SignupConfirmInput;
};


export type MutationSignupGuestArgs = {
  deviceToken?: Maybe<Scalars['String']>;
  deviceTokenType?: Maybe<Scalars['String']>;
  deviceUid: Scalars['String'];
  deviceType: Scalars['String'];
  refererUuid?: Maybe<Scalars['String']>;
};


export type MutationSignupOauthArgs = {
  deviceUid: Scalars['String'];
  deviceType: Scalars['String'];
  provider: Scalars['String'];
  uid: Scalars['String'];
  deviceToken?: Maybe<Scalars['String']>;
  deviceTokenType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  refererUuid?: Maybe<Scalars['String']>;
};


export type MutationSignupWithUidArgs = {
  input: SignupWithUidInput;
};


export type MutationSubmitAppReviewArgs = {
  review: Scalars['String'];
};


export type MutationUpdateCustomNotificationArgs = {
  customNotificationId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateDeviceTokenArgs = {
  token: Scalars['String'];
  deviceTokenType?: Maybe<Scalars['String']>;
};


export type MutationUpdateDeviceVersionArgs = {
  versionNumber: Scalars['String'];
};


export type MutationUpdateInitialPrimaryLanguageArgs = {
  languageCode: Scalars['String'];
};


export type MutationUpdateNotificationPreferenceArgs = {
  video?: Maybe<VideoEnum>;
  publisher?: Maybe<PublisherEnum>;
  newsStack?: Maybe<NewsStackEnum>;
  chat?: Maybe<ChatEnum>;
  socialActivity?: Maybe<SocialActivityEnum>;
};


export type MutationUpdatePrimaryLanguageArgs = {
  languageId: Scalars['ID'];
};


export type MutationUpdatePrnNotificationPreferenceArgs = {
  selangor?: Maybe<Scalars['Boolean']>;
  penang?: Maybe<Scalars['Boolean']>;
  kelantan?: Maybe<Scalars['Boolean']>;
  terengganu?: Maybe<Scalars['Boolean']>;
  kedah?: Maybe<Scalars['Boolean']>;
  negeriSembilan?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserPreferenceArgs = {
  readerMode?: Maybe<ReaderModeEnum>;
  theme?: Maybe<ThemeEnum>;
  latestTabView?: Maybe<LatestTabViewEnum>;
  newsListView?: Maybe<NewsListViewEnum>;
  homeFeedMode?: Maybe<HomeFeedModeEnum>;
  readerModeFontScale?: Maybe<Scalars['Float']>;
};


export type MutationUpdateUserProfileArgs = {
  displayName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  profileImageId?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
};


export type MutationViewVideoArgs = {
  videoId: Scalars['ID'];
};

export type News = {
  __typename?: 'News';
  bookmark: Scalars['Boolean'];
  comments: Array<Comment>;
  commentsCount: Scalars['Int'];
  content: Scalars['String'];
  description: Scalars['String'];
  good: Scalars['Boolean'];
  hasNewsContent: Scalars['Boolean'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isAdultNonMuslimContent: Scalars['Boolean'];
  isUnderNewsStack: Scalars['Boolean'];
  like: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  link: Scalars['String'];
  newsPublisher: NewsPublisher;
  nextNews?: Maybe<Array<News>>;
  ogImageUrl?: Maybe<Scalars['String']>;
  pubDate: Scalars['ISO8601DateTime'];
  shareCount: Scalars['Int'];
  shareUrl: Scalars['String'];
  shouldShowContentFeedbackTooltip: Scalars['Boolean'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  topics: Array<Category>;
};


export type NewsCommentsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type NewsNextNewsArgs = {
  languageCode?: Maybe<Scalars['String']>;
};

export type NewsContent = {
  __typename?: 'NewsContent';
  content: Scalars['String'];
  id: Scalars['ID'];
};

export enum NewsListViewEnum {
  List = 'list',
  Wide = 'wide'
}

export type NewsPublisher = {
  __typename?: 'NewsPublisher';
  badges?: Maybe<Array<Scalars['String']>>;
  bigImageUrl: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  follow?: Maybe<Scalars['Boolean']>;
  hasNews: Scalars['Boolean'];
  hasShorts: Scalars['Boolean'];
  hasVideos: Scalars['Boolean'];
  iconUrl: Scalars['String'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  isKol: Scalars['Boolean'];
  isNewsContentAvailable: Scalars['Boolean'];
  language: Language;
  name: Scalars['String'];
  new: Scalars['Boolean'];
  news?: Maybe<Array<News>>;
  phoneNumber?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  url: Scalars['String'];
  vectorUrl?: Maybe<Scalars['String']>;
  videos?: Maybe<Array<Video>>;
};


export type NewsPublisherNewsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type NewsPublisherVideosArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};

export enum NewsQuery {
  UserFeed = 'userFeed',
  Search = 'search',
  Category = 'category',
  Bookmark = 'bookmark',
  Latest = 'latest',
  GoodNews = 'goodNews'
}

export enum NewsStackEnum {
  SeeAll = 'seeAll',
  Disable = 'disable'
}

export enum NewsStacksRecommendationsContentTypeEnum {
  News = 'News',
  Video = 'Video'
}

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  extraData?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  news?: Maybe<News>;
  path?: Maybe<Scalars['String']>;
  read: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type NotificationCount = {
  __typename?: 'NotificationCount';
  count: Scalars['Int'];
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  chat: ChatEnum;
  id: Scalars['ID'];
  newsStack: NewsStackEnum;
  publisher: PublisherEnum;
  socialActivity: SocialActivityEnum;
  video: VideoEnum;
};

/** Attributes for Infinite Scrolling */
export type OffsetAttributes = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of OnboardUser */
export type OnboardUserPayload = {
  __typename?: 'OnboardUserPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

export enum OrientationEnum {
  Landscape = 'landscape',
  Portrait = 'portrait'
}

export type PhoneNumberBinding = {
  __typename?: 'PhoneNumberBinding';
  canBind: Scalars['Boolean'];
};

export type Playlist = {
  __typename?: 'Playlist';
  id: Scalars['ID'];
  name: Scalars['String'];
  shorts: Array<Video>;
  shortsCount: Scalars['Int'];
};


export type PlaylistShortsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};

export type Prn2023NotificationPreference = {
  __typename?: 'Prn2023NotificationPreference';
  id: Scalars['ID'];
  kedah: Scalars['Boolean'];
  kelantan: Scalars['Boolean'];
  negeriSembilan: Scalars['Boolean'];
  penang: Scalars['Boolean'];
  selangor: Scalars['Boolean'];
  terengganu: Scalars['Boolean'];
};

export type ProfileImage = {
  __typename?: 'ProfileImage';
  id: Scalars['String'];
  url: Scalars['String'];
};

export enum PublisherEnum {
  SeeAll = 'seeAll',
  Disable = 'disable'
}

export enum PublishersFilterTypeEnum {
  NewsPublisher = 'news_publisher',
  Kol = 'kol'
}

export type Query = {
  __typename?: 'Query';
  allCategories?: Maybe<Array<Category>>;
  allComments: Array<Comment>;
  allKols: Array<NewsPublisher>;
  allNews?: Maybe<Array<News>>;
  allReplies: Array<Comment>;
  allShorts: Array<Video>;
  allTrendingVideos: Array<Video>;
  allVideos?: Maybe<Array<Video>>;
  appBanner?: Maybe<AppBanner>;
  bookmarkContents?: Maybe<Array<Content>>;
  carousels?: Maybe<Array<CarouselContentNews>>;
  categoriesWithIds?: Maybe<Array<Category>>;
  category?: Maybe<Category>;
  checkPhoneNumberBinding: PhoneNumberBinding;
  comment?: Maybe<Comment>;
  contentCommentsCount?: Maybe<ContentCommentsCount>;
  covidStat: CovidStat;
  currentCampaign?: Maybe<Campaign>;
  customBanner: CustomBanner;
  customNotifications: Array<CustomNotification>;
  featuredNews?: Maybe<News>;
  featuredVideo?: Maybe<Video>;
  firstNewsInFeed?: Maybe<News>;
  followedCategories?: Maybe<Array<Category>>;
  followedPublishers?: Maybe<Array<NewsPublisher>>;
  fypFeed: Array<FypFeedContent>;
  fypFeedLastUpdateAt: FypFeedLastUpdateAt;
  fypHeroCardContent: Array<FypHeroCardContent>;
  fypShorts: Array<Video>;
  ge15FeaturedVideo?: Maybe<Video>;
  ge15Stacks: Array<Ge15Stack>;
  ge15Status: Ge15Status;
  languages: Array<Language>;
  latestCommentedContent?: Maybe<Array<ContentWithTopComments>>;
  latestComments?: Maybe<Array<Comment>>;
  latestTabFeed: Array<LatestTabFeedContent>;
  latestTabHeroCardContent: Array<LatestTabHeroCardContent>;
  latestVideos?: Maybe<Array<Video>>;
  me?: Maybe<User>;
  news?: Maybe<News>;
  newsContent?: Maybe<NewsContent>;
  newsRecommendationsForKeyword: Array<News>;
  newsStacksRecommendationsForContent: Array<Category>;
  newsWithIds?: Maybe<Array<News>>;
  notificationPreference: NotificationPreference;
  notifications?: Maybe<Array<Notification>>;
  notificationsUnreadCount: NotificationCount;
  onboardingCategories: Array<Category>;
  playlist?: Maybe<Playlist>;
  profileImages: Array<ProfileImage>;
  publisher?: Maybe<NewsPublisher>;
  publisherShorts: Array<Video>;
  publishers?: Maybe<Array<NewsPublisher>>;
  queryPublisher?: Maybe<NewsPublisher>;
  recommendedNews?: Maybe<Array<News>>;
  referralCount?: Maybe<ReferralCount>;
  searchCount?: Maybe<SearchCount>;
  shortsRecommendationsForContent: Array<Video>;
  socialNotifications: Array<SocialNotification>;
  socialNotificationsUnreadCount: SocialNotificationCount;
  suggestedKeywords: Array<Scalars['String']>;
  topNews?: Maybe<Array<News>>;
  topic: Topic;
  topicStack: TopicStack;
  user?: Maybe<UserPublic>;
  userCategoriesInOrder: Array<Category>;
  vaccinateStat: VaccinateStat;
  video?: Maybe<Video>;
  videosRecommendationsForContent: Array<Video>;
  videosWithIds?: Maybe<Array<Video>>;
};


export type QueryAllCategoriesArgs = {
  show?: Maybe<Scalars['Boolean']>;
  explore?: Maybe<Scalars['Boolean']>;
  languageCode?: Maybe<Scalars['String']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
  search?: Maybe<Scalars['String']>;
  hideGoodNews?: Maybe<Scalars['Boolean']>;
  discover?: Maybe<Scalars['String']>;
};


export type QueryAllCommentsArgs = {
  contentId: Scalars['ID'];
  contentType: CommentTypeEnum;
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryAllKolsArgs = {
  new?: Maybe<Scalars['Boolean']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryAllNewsArgs = {
  type: NewsQuery;
  search?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['ID']>;
  categoryName?: Maybe<Scalars['String']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
  random?: Maybe<Scalars['Boolean']>;
  languageCodes?: Maybe<Array<Scalars['String']>>;
};


export type QueryAllRepliesArgs = {
  commentId: Scalars['ID'];
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryAllShortsArgs = {
  search?: Maybe<Scalars['String']>;
  playlistId?: Maybe<Scalars['String']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
  page?: Maybe<Scalars['Int']>;
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryAllTrendingVideosArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryAllVideosArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
  languageCodes?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  portraitOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryAppBannerArgs = {
  key: Scalars['String'];
  languageCode?: Maybe<Scalars['String']>;
  theme?: Maybe<AppBannerThemeEnum>;
};


export type QueryBookmarkContentsArgs = {
  contentType: BookmarkTypeEnum;
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryCarouselsArgs = {
  publisherId: Scalars['ID'];
  categories?: Maybe<Array<Scalars['ID']>>;
};


export type QueryCategoriesWithIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryCheckPhoneNumberBindingArgs = {
  phoneNumber: Scalars['String'];
};


export type QueryCommentArgs = {
  id: Scalars['ID'];
};


export type QueryContentCommentsCountArgs = {
  contentId: Scalars['ID'];
  contentType: CommentTypeEnum;
};


export type QueryCurrentCampaignArgs = {
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryCustomBannerArgs = {
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryFeaturedNewsArgs = {
  categoryId?: Maybe<Scalars['ID']>;
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryFeaturedVideoArgs = {
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryFirstNewsInFeedArgs = {
  languageCodes?: Maybe<Array<Scalars['String']>>;
};


export type QueryFollowedCategoriesArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryFollowedPublishersArgs = {
  filterType?: Maybe<PublishersFilterTypeEnum>;
};


export type QueryFypFeedArgs = {
  page?: Maybe<Scalars['Int']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryFypHeroCardContentArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QueryFypShortsArgs = {
  page?: Maybe<Scalars['Int']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryGe15FeaturedVideoArgs = {
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryGe15StacksArgs = {
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryLatestCommentedContentArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryLatestTabFeedArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QueryLatestTabHeroCardContentArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QueryLatestVideosArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
  languageCodes?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryNewsArgs = {
  id: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryNewsContentArgs = {
  newsId: Scalars['ID'];
};


export type QueryNewsRecommendationsForKeywordArgs = {
  keyword: Scalars['String'];
  languageCode: Scalars['String'];
};


export type QueryNewsStacksRecommendationsForContentArgs = {
  contentId: Scalars['ID'];
  contentType: NewsStacksRecommendationsContentTypeEnum;
};


export type QueryNewsWithIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryNotificationsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryNotificationsUnreadCountArgs = {
  afterDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type QueryOnboardingCategoriesArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryPlaylistArgs = {
  id: Scalars['ID'];
};


export type QueryPublisherArgs = {
  id?: Maybe<Scalars['ID']>;
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryPublisherShortsArgs = {
  id: Scalars['ID'];
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryPublishersArgs = {
  new?: Maybe<Scalars['Boolean']>;
  random?: Maybe<Scalars['Boolean']>;
  languageIds?: Maybe<Array<Scalars['ID']>>;
  languageCodes?: Maybe<Array<Scalars['String']>>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  search?: Maybe<Scalars['String']>;
  offsetAttributes?: Maybe<OffsetAttributes>;
  filterType?: Maybe<PublishersFilterTypeEnum>;
};


export type QueryQueryPublisherArgs = {
  id?: Maybe<Scalars['ID']>;
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryRecommendedNewsArgs = {
  newsId: Scalars['ID'];
};


export type QuerySearchCountArgs = {
  search: Scalars['String'];
};


export type QueryShortsRecommendationsForContentArgs = {
  contentId: Scalars['ID'];
  contentType: ShortsRecommendationsContentTypeEnum;
};


export type QuerySocialNotificationsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QuerySocialNotificationsUnreadCountArgs = {
  afterDate?: Maybe<Scalars['ISO8601DateTime']>;
};


export type QuerySuggestedKeywordsArgs = {
  languageCode: Scalars['String'];
};


export type QueryTopNewsArgs = {
  languageCode: Scalars['String'];
};


export type QueryTopicArgs = {
  id: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryTopicStackArgs = {
  id: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  uuid: Scalars['String'];
};


export type QueryUserCategoriesInOrderArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};


export type QueryVideoArgs = {
  id: Scalars['ID'];
};


export type QueryVideosRecommendationsForContentArgs = {
  contentId: Scalars['ID'];
  contentType: ShortsRecommendationsContentTypeEnum;
};


export type QueryVideosWithIdsArgs = {
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of ReadNotification */
export type ReadNotificationPayload = {
  __typename?: 'ReadNotificationPayload';
  errors?: Maybe<Array<Error>>;
  notification?: Maybe<Notification>;
};

/** Autogenerated return type of ReadSocialNotification */
export type ReadSocialNotificationPayload = {
  __typename?: 'ReadSocialNotificationPayload';
  errors?: Maybe<Array<Error>>;
  socialNotification?: Maybe<SocialNotification>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum ReaderModeEnum {
  On = 'on',
  Off = 'off'
}

export type ReferralCount = {
  __typename?: 'ReferralCount';
  count: Scalars['Int'];
};

/** Autogenerated return type of ReplyComment */
export type ReplyCommentPayload = {
  __typename?: 'ReplyCommentPayload';
  commentable?: Maybe<Commentable>;
  errors?: Maybe<Array<Error>>;
  repliesCount?: Maybe<Scalars['Int']>;
  reply?: Maybe<Comment>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ReportChatMessage */
export type ReportChatMessagePayload = {
  __typename?: 'ReportChatMessagePayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ReportComment */
export type ReportCommentPayload = {
  __typename?: 'ReportCommentPayload';
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type RequestPhoneCodeInput = {
  phoneNumber: Scalars['String'];
};

/** Autogenerated return type of RequestPhoneCode */
export type RequestPhoneCodePayload = {
  __typename?: 'RequestPhoneCodePayload';
  errors?: Maybe<Array<Error>>;
  userId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ResendOtp */
export type ResendOtpPayload = {
  __typename?: 'ResendOtpPayload';
  errors?: Maybe<Array<Error>>;
  userId?: Maybe<Scalars['ID']>;
};

export type RichContent = {
  __typename?: 'RichContent';
  categoryName: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  data: Scalars['JSON'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SearchCount = {
  __typename?: 'SearchCount';
  category: Scalars['Int'];
  news: Scalars['Int'];
  newsStack: Scalars['Int'];
  publisher: Scalars['Int'];
  short: Scalars['Int'];
  topic: Scalars['Int'];
  video: Scalars['Int'];
};

/** Autogenerated return type of ShareContent */
export type ShareContentPayload = {
  __typename?: 'ShareContentPayload';
  errors?: Maybe<Array<Error>>;
  shareCount?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum ShareTypeEnum {
  News = 'News',
  Video = 'Video',
  Category = 'Category'
}

export enum ShortsRecommendationsContentTypeEnum {
  News = 'News',
  Video = 'Video'
}

export type SignupConfirmInput = {
  userId: Scalars['ID'];
  phoneCode: Scalars['String'];
  deviceToken?: Maybe<Scalars['String']>;
  deviceTokenType?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SignupConfirm */
export type SignupConfirmPayload = {
  __typename?: 'SignupConfirmPayload';
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
};

/** Autogenerated return type of SignupGuest */
export type SignupGuestPayload = {
  __typename?: 'SignupGuestPayload';
  errors?: Maybe<Array<Error>>;
  firstSignIn?: Maybe<Scalars['Boolean']>;
  hasPrimaryLanguage?: Maybe<Scalars['Boolean']>;
  jwt?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
};

export type SignupInput = {
  primaryLanguageCode?: Maybe<Scalars['String']>;
  favouriteCategoryIds: Array<Scalars['ID']>;
  phoneNumber: Scalars['String'];
  customNotificationId?: Maybe<Scalars['ID']>;
  followedNewsPublisherIds?: Maybe<Array<Scalars['ID']>>;
  goodNews?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SignupOauth */
export type SignupOauthPayload = {
  __typename?: 'SignupOauthPayload';
  errors?: Maybe<Array<Error>>;
  firstSignIn: Scalars['Boolean'];
  hasOnboardedPrimaryLanguage: Scalars['Boolean'];
  jwt?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
};

/** Autogenerated return type of Signup */
export type SignupPayload = {
  __typename?: 'SignupPayload';
  errors?: Maybe<Array<Error>>;
  userId?: Maybe<Scalars['ID']>;
};

export type SignupWithUidInput = {
  primaryLanguageCode?: Maybe<Scalars['String']>;
  favouriteCategoryIds: Array<Scalars['ID']>;
  customNotificationId?: Maybe<Scalars['ID']>;
  deviceToken?: Maybe<Scalars['String']>;
  deviceTokenType?: Maybe<Scalars['String']>;
  deviceUid: Scalars['String'];
  deviceType: Scalars['String'];
  followedNewsPublisherIds?: Maybe<Array<Scalars['ID']>>;
  goodNews?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SignupWithUid */
export type SignupWithUidPayload = {
  __typename?: 'SignupWithUidPayload';
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
};

export enum SocialActivityEnum {
  SeeAll = 'seeAll',
  Disable = 'disable'
}

export type SocialNotification = {
  __typename?: 'SocialNotification';
  contentTitle: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  read: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type SocialNotificationCount = {
  __typename?: 'SocialNotificationCount';
  count: Scalars['Int'];
};

export type StateCovidStat = {
  __typename?: 'StateCovidStat';
  hospitalUsagePercentage?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  newCases?: Maybe<Scalars['Int']>;
  totalCases?: Maybe<Scalars['Int']>;
};

export type StateVaccinateStat = {
  __typename?: 'StateVaccinateStat';
  id: Scalars['ID'];
  name: Scalars['String'];
  vaccinatedPercentage: Scalars['Float'];
};

/** Autogenerated return type of SubmitAppReview */
export type SubmitAppReviewPayload = {
  __typename?: 'SubmitAppReviewPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SubscribeGoodNewsNotification */
export type SubscribeGoodNewsNotificationPayload = {
  __typename?: 'SubscribeGoodNewsNotificationPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of SubscribeGoodNews */
export type SubscribeGoodNewsPayload = {
  __typename?: 'SubscribeGoodNewsPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

export enum ThemeEnum {
  Light = 'light',
  Dark = 'dark',
  Adaptive = 'adaptive'
}

export type Topic = {
  __typename?: 'Topic';
  blocks?: Maybe<Scalars['JSON']>;
  feed?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
};


export type TopicFeedArgs = {
  page?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type TopicStack = {
  __typename?: 'TopicStack';
  categories?: Maybe<Array<Category>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

/** Autogenerated return type of UpdateCustomNotification */
export type UpdateCustomNotificationPayload = {
  __typename?: 'UpdateCustomNotificationPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of UpdateDeviceToken */
export type UpdateDeviceTokenPayload = {
  __typename?: 'UpdateDeviceTokenPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of UpdateDeviceVersion */
export type UpdateDeviceVersionPayload = {
  __typename?: 'UpdateDeviceVersionPayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateInitialPrimaryLanguage */
export type UpdateInitialPrimaryLanguagePayload = {
  __typename?: 'UpdateInitialPrimaryLanguagePayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of Update */
export type UpdatePayload = {
  __typename?: 'UpdatePayload';
  errors?: Maybe<Array<Error>>;
  notificationPreference?: Maybe<NotificationPreference>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdatePrimaryLanguage */
export type UpdatePrimaryLanguagePayload = {
  __typename?: 'UpdatePrimaryLanguagePayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
};

/** Autogenerated return type of UpdatePrn2023NotificationPreference */
export type UpdatePrn2023NotificationPreferencePayload = {
  __typename?: 'UpdatePrn2023NotificationPreferencePayload';
  errors?: Maybe<Array<Error>>;
  prnNotificationPreference?: Maybe<Prn2023NotificationPreference>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateUserPreference */
export type UpdateUserPreferencePayload = {
  __typename?: 'UpdateUserPreferencePayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
  userPreference?: Maybe<UserPreference>;
};

/** Autogenerated return type of UpdateUserProfile */
export type UpdateUserProfilePayload = {
  __typename?: 'UpdateUserProfilePayload';
  errors?: Maybe<Array<Error>>;
  me?: Maybe<User>;
  success?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  confirm: Scalars['Boolean'];
  customNotification?: Maybe<CustomNotification>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  favouriteCategories: Array<Category>;
  followedNewsPublishers: Array<NewsPublisher>;
  goodNews: Scalars['Boolean'];
  goodNewsNotification: Scalars['Boolean'];
  hasDoneOnboarding: Scalars['Boolean'];
  hasOnboardedLanguage: Scalars['Boolean'];
  hasSocialLogins: Scalars['Boolean'];
  hasUpdatedBio: Scalars['Boolean'];
  hasUpdatedDisplayName: Scalars['Boolean'];
  hasUpdatedUsername: Scalars['Boolean'];
  id: Scalars['ID'];
  isBannedFromComment: Scalars['Boolean'];
  isGuest: Scalars['Boolean'];
  isInternalTester: Scalars['Boolean'];
  isNewsContentAllowed: Scalars['Boolean'];
  joinedAt: Scalars['ISO8601DateTime'];
  lastLoginWith?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preference?: Maybe<UserPreference>;
  primaryLanguage: Language;
  prnNotificationPreference?: Maybe<Prn2023NotificationPreference>;
  username?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  verified: Scalars['Boolean'];
};

export enum UserContentFeedbackContentTypeEnum {
  News = 'News',
  Video = 'Video',
  Short = 'Short'
}

export enum UserContentFeedbackSentimentEnum {
  ShowMore = 'show_more',
  ShowLess = 'show_less'
}

export enum UserNewsPublisherFeedbackSentimentEnum {
  ShowMore = 'show_more',
  ShowLess = 'show_less'
}

export type UserPreference = {
  __typename?: 'UserPreference';
  homeFeedMode: HomeFeedModeEnum;
  id: Scalars['ID'];
  latestTabView: LatestTabViewEnum;
  newsListView: NewsListViewEnum;
  readerMode: ReaderModeEnum;
  readerModeFontScale: Scalars['Float'];
  theme: ThemeEnum;
};

export type UserPublic = {
  __typename?: 'UserPublic';
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  joinedAt: Scalars['ISO8601DateTime'];
  username?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type VaccinateStat = {
  __typename?: 'VaccinateStat';
  adultVaccinatedPercentage: Scalars['Float'];
  boosterCumul: Scalars['Int'];
  boosterDaily: Scalars['Int'];
  boosterPercentage: Scalars['Float'];
  date: Scalars['ISO8601DateTime'];
  dose1Cumul: Scalars['Int'];
  dose1Daily: Scalars['Int'];
  dose1Percentage: Scalars['Float'];
  dose2Cumul: Scalars['Int'];
  dose2Daily: Scalars['Int'];
  id: Scalars['ID'];
  stateData: Array<StateVaccinateStat>;
  totalCumul: Scalars['Int'];
  totalDaily: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  vaccinatedPercentage: Scalars['Float'];
};

export type Video = {
  __typename?: 'Video';
  bookmark: Scalars['Boolean'];
  comments: Array<Comment>;
  commentsCount: Scalars['Int'];
  description: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isShort?: Maybe<Scalars['Boolean']>;
  isWebViewable?: Maybe<Scalars['Boolean']>;
  like: Scalars['Boolean'];
  likeCount: Scalars['Int'];
  likesCount: Scalars['Int'];
  liveChatId?: Maybe<Scalars['String']>;
  newsPublisher: NewsPublisher;
  orientation: OrientationEnum;
  pubDate: Scalars['ISO8601DateTime'];
  shareCount: Scalars['Int'];
  shareUrl: Scalars['String'];
  shouldShowContentFeedbackTooltip: Scalars['Boolean'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  viewCount: Scalars['Int'];
};


export type VideoCommentsArgs = {
  offsetAttributes?: Maybe<OffsetAttributes>;
};

export enum VideoEnum {
  SeeAll = 'seeAll',
  Disable = 'disable'
}

/** Autogenerated return type of ViewVideo */
export type ViewVideoPayload = {
  __typename?: 'ViewVideoPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
  video?: Maybe<Video>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type AuthorDetailsFragment = (
  { __typename?: 'Author' }
  & Pick<Author, 'id' | 'name' | 'imageUrl' | 'verified' | 'authorType'>
);

export type CommentDetailsFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'createdAt' | 'content' | 'likesCount' | 'commentsCount'>
  & { topReplies?: Maybe<Array<(
    { __typename?: 'Comment' }
    & ReplyDetailsFragment
  )>>, user: (
    { __typename?: 'UserPublic' }
    & UserDetailsFragment
  ) }
);

export type ExploreTopicSummaryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'key' | 'slug' | 'imageUrl' | 'hot' | 'updated' | 'exclusive' | 'exploreName' | 'description' | 'thumbnailUrl' | 'newsCount' | 'publishersCount' | 'shareCount' | 'likesCount' | 'commentsCount' | 'updatedAt'>
  & { author?: Maybe<(
    { __typename?: 'Author' }
    & AuthorDetailsFragment
  )> }
);

export type NewsPublisherDetailsFragment = (
  { __typename?: 'NewsPublisher' }
  & Pick<NewsPublisher, 'id' | 'name' | 'slug' | 'description' | 'bigImageUrl' | 'iconUrl' | 'new' | 'badges' | 'imageUrl'>
);

export type NewsSummaryFragment = (
  { __typename?: 'News' }
  & Pick<News, 'id' | 'imageUrl' | 'thumbnailUrl' | 'title' | 'description' | 'good' | 'pubDate' | 'shareCount' | 'commentsCount'>
  & { newsPublisher: (
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name' | 'slug' | 'iconUrl' | 'imageUrl'>
  ) }
);

export type ReplyDetailsFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'createdAt' | 'content' | 'likesCount'>
  & { user: (
    { __typename?: 'UserPublic' }
    & UserDetailsFragment
  ) }
);

export type UserDetailsFragment = (
  { __typename?: 'UserPublic' }
  & Pick<UserPublic, 'uuid' | 'avatar' | 'verified' | 'username' | 'displayName'>
);

export type VideoSummaryFragment = (
  { __typename?: 'Video' }
  & Pick<Video, 'id' | 'imageUrl' | 'externalId' | 'title' | 'pubDate'>
  & { newsPublisher: (
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name' | 'slug' | 'iconUrl' | 'imageUrl'>
  ) }
);

export type AboutPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AboutPageQuery = (
  { __typename?: 'Query' }
  & { languages: Array<(
    { __typename?: 'Language' }
    & Pick<Language, 'id' | 'code' | 'name'>
    & { newsPublishers: Array<(
      { __typename?: 'NewsPublisher' }
      & NewsPublisherDetailsFragment
    )> }
  )>, publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name' | 'slug' | 'vectorUrl' | 'imageUrl' | 'iconUrl'>
  )>> }
);

export type CovidPageQueryVariables = Exact<{ [key: string]: never; }>;


export type CovidPageQuery = (
  { __typename?: 'Query' }
  & { covidStat: (
    { __typename?: 'CovidStat' }
    & Pick<CovidStat, 'id' | 'newCases' | 'activeCases' | 'recovered' | 'deaths' | 'totalCases' | 'totalRecovered' | 'totalDeaths' | 'date' | 'updatedAt' | 'hospitalUsagePercentage' | 'icuUsagePercentage'>
    & { stateData: Array<(
      { __typename?: 'StateCovidStat' }
      & Pick<StateCovidStat, 'id' | 'name' | 'newCases' | 'totalCases'>
    )> }
  ), vaccinateStat: (
    { __typename?: 'VaccinateStat' }
    & Pick<VaccinateStat, 'id' | 'totalDaily' | 'dose1Cumul' | 'dose2Cumul' | 'dose1Percentage' | 'vaccinatedPercentage' | 'boosterCumul' | 'boosterDaily' | 'boosterPercentage' | 'adultVaccinatedPercentage' | 'totalCumul' | 'date' | 'updatedAt'>
    & { stateData: Array<(
      { __typename?: 'StateVaccinateStat' }
      & Pick<StateVaccinateStat, 'id' | 'name' | 'vaccinatedPercentage'>
    )> }
  ), customBanner: (
    { __typename?: 'CustomBanner' }
    & Pick<CustomBanner, 'covidTabBanner' | 'dailyDosesLabel' | 'vaccinationGoalLabel' | 'totalDosesLabel' | 'oneDoseLabel' | 'twoDosesLabel' | 'newCovidCasesLabel' | 'confirmedCasesLabel'>
  ), covidNews?: Maybe<Array<(
    { __typename?: 'News' }
    & NewsSummaryFragment
  )>>, goodNews?: Maybe<Array<(
    { __typename?: 'News' }
    & NewsSummaryFragment
  )>> }
);

export type Ge15PageQueryVariables = Exact<{
  languageCode?: Maybe<Scalars['String']>;
}>;


export type Ge15PageQuery = (
  { __typename?: 'Query' }
  & { ge15FeaturedVideo?: Maybe<(
    { __typename?: 'Video' }
    & VideoSummaryFragment
  )>, ge15Stacks: Array<(
    { __typename?: 'Ge15Stack' }
    & Pick<Ge15Stack, 'id' | 'title' | 'description'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'key' | 'slug' | 'imageUrl' | 'hot' | 'exploreName' | 'updatedAt'>
    )> }
  )>, allCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'key' | 'slug' | 'imageUrl' | 'hot' | 'updated' | 'exploreName' | 'description'>
  )>> }
);

export type HomePageQueryVariables = Exact<{
  languageCode: Scalars['String'];
}>;


export type HomePageQuery = (
  { __typename?: 'Query' }
  & { allCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & ExploreTopicSummaryFragment
  )>>, topExploreCategory?: Maybe<Array<(
    { __typename?: 'Category' }
    & { news?: Maybe<Array<(
      { __typename?: 'News' }
      & NewsSummaryFragment
    )>>, richContent?: Maybe<(
      { __typename?: 'RichContent' }
      & Pick<RichContent, 'id' | 'data' | 'updatedAt'>
    )> }
    & ExploreTopicSummaryFragment
  )>>, allVideos?: Maybe<Array<(
    { __typename?: 'Video' }
    & VideoSummaryFragment
  )>> }
);

export type CategoriesWithIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type CategoriesWithIdsQuery = (
  { __typename?: 'Query' }
  & { categoriesWithIds?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'key' | 'slug' | 'imageUrl' | 'exploreName' | 'hot' | 'updated' | 'exclusive' | 'description'>
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & AuthorDetailsFragment
    )> }
  )>> }
);

export type NewsWithIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type NewsWithIdsQuery = (
  { __typename?: 'Query' }
  & { newsWithIds?: Maybe<Array<(
    { __typename?: 'News' }
    & Pick<News, 'id' | 'title' | 'description' | 'imageUrl' | 'good' | 'pubDate'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'iconUrl' | 'imageUrl' | 'name' | 'slug'>
    ) }
  )>> }
);

export type VideosWithIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type VideosWithIdsQuery = (
  { __typename?: 'Query' }
  & { videosWithIds?: Maybe<Array<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'externalId' | 'title'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'iconUrl' | 'name' | 'slug'>
    ) }
  )>> }
);

export type NewsStackPageQueryVariables = Exact<{
  id: Scalars['ID'];
  languageCode: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
}>;


export type NewsStackPageQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & { richContent?: Maybe<(
      { __typename?: 'RichContent' }
      & Pick<RichContent, 'data' | 'updatedAt'>
    )>, news?: Maybe<Array<(
      { __typename?: 'News' }
      & NewsSummaryFragment
    )>>, comments: Array<(
      { __typename?: 'Comment' }
      & CommentDetailsFragment
    )> }
    & ExploreTopicSummaryFragment
  )> }
);

export type AllNewsStackQueryVariables = Exact<{
  languageCode?: Maybe<Scalars['String']>;
}>;


export type AllNewsStackQuery = (
  { __typename?: 'Query' }
  & { allCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'slug' | 'key' | 'hot' | 'exploreName' | 'updated' | 'exclusive'>
  )>> }
);

export type NewsPageQueryVariables = Exact<{
  id: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
}>;


export type NewsPageQuery = (
  { __typename?: 'Query' }
  & { news?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, 'id' | 'title' | 'description' | 'content' | 'pubDate' | 'thumbnailUrl' | 'link' | 'imageUrl' | 'isUnderNewsStack' | 'commentsCount' | 'shareCount'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name' | 'slug' | 'imageUrl'>
      & { language: (
        { __typename?: 'Language' }
        & Pick<Language, 'code'>
      ) }
    ), nextNews?: Maybe<Array<(
      { __typename?: 'News' }
      & NewsSummaryFragment
    )>>, topics: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'key' | 'name' | 'emoji'>
    )>, comments: Array<(
      { __typename?: 'Comment' }
      & CommentDetailsFragment
    )> }
  )> }
);

export type AllNewsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;


export type AllNewsQuery = (
  { __typename?: 'Query' }
  & { allNews?: Maybe<Array<(
    { __typename?: 'News' }
    & Pick<News, 'id' | 'title' | 'imageUrl' | 'pubDate'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name'>
      & { language: (
        { __typename?: 'Language' }
        & Pick<Language, 'id' | 'code'>
      ) }
    ) }
  )>> }
);

export type NewsLandingPageQueryVariables = Exact<{
  languageCode: Scalars['String'];
}>;


export type NewsLandingPageQuery = (
  { __typename?: 'Query' }
  & { allCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'key' | 'name' | 'slug' | 'imageUrl' | 'hot' | 'exploreName' | 'emoji'>
  )>>, topExploreCategory?: Maybe<Array<(
    { __typename?: 'Category' }
    & { news?: Maybe<Array<(
      { __typename?: 'News' }
      & NewsSummaryFragment
    )>> }
    & ExploreTopicSummaryFragment
  )>> }
);

export type Prn2023PageQueryVariables = Exact<{
  id: Scalars['ID'];
  languageCode: Scalars['String'];
}>;


export type Prn2023PageQuery = (
  { __typename?: 'Query' }
  & { topicStack: (
    { __typename?: 'TopicStack' }
    & Pick<TopicStack, 'title' | 'description'>
    & { categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & { richContent?: Maybe<(
        { __typename?: 'RichContent' }
        & Pick<RichContent, 'id' | 'data' | 'updatedAt'>
      )> }
      & ExploreTopicSummaryFragment
    )>> }
  ) }
);

export type PublisherPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublisherPageQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'NewsPublisher' }
    & NewsPublisherDetailsFragment
  )> }
);

export type PublisherNewsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
}>;


export type PublisherNewsQuery = (
  { __typename?: 'Query' }
  & { publisher?: Maybe<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id'>
    & { news?: Maybe<Array<(
      { __typename?: 'News' }
      & NewsSummaryFragment
    )>>, videos?: Maybe<Array<(
      { __typename?: 'Video' }
      & VideoSummaryFragment
    )>> }
  )> }
);

export type PublishersPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PublishersPageQuery = (
  { __typename?: 'Query' }
  & { publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name' | 'slug'>
  )>> }
);

export type PublishersLandingPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PublishersLandingPageQuery = (
  { __typename?: 'Query' }
  & { languages: Array<(
    { __typename?: 'Language' }
    & Pick<Language, 'id' | 'code' | 'name'>
    & { newsPublishers: Array<(
      { __typename?: 'NewsPublisher' }
      & NewsPublisherDetailsFragment
    )> }
  )> }
);

export type SearchQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
  languageCode?: Maybe<Scalars['String']>;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { allNews?: Maybe<Array<(
    { __typename?: 'News' }
    & NewsSummaryFragment
  )>>, allVideos?: Maybe<Array<(
    { __typename?: 'Video' }
    & VideoSummaryFragment
  )>>, publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & NewsPublisherDetailsFragment
  )>>, allCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & ExploreTopicSummaryFragment
  )>>, allShorts: Array<(
    { __typename?: 'Video' }
    & VideoSummaryFragment
  )>, searchCount?: Maybe<(
    { __typename?: 'SearchCount' }
    & Pick<SearchCount, 'news' | 'video' | 'short'>
  )> }
);

export type ShortsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  after: Scalars['ID'];
}>;


export type ShortsQuery = (
  { __typename?: 'Query' }
  & { video?: Maybe<(
    { __typename?: 'Video' }
    & VideoSummaryFragment
  )>, allShorts: Array<(
    { __typename?: 'Video' }
    & VideoSummaryFragment
  )> }
);

export type TopicFeedQueryVariables = Exact<{
  slug: Scalars['ID'];
  languageCode?: Maybe<Scalars['String']>;
}>;


export type TopicFeedQuery = (
  { __typename?: 'Query' }
  & { topic: (
    { __typename?: 'Topic' }
    & Pick<Topic, 'id' | 'feed' | 'blocks'>
  ), category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'exploreName' | 'description'>
  )> }
);

export type TopicFeedPaginationQueryVariables = Exact<{
  slug: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  languageCode?: Maybe<Scalars['String']>;
}>;


export type TopicFeedPaginationQuery = (
  { __typename?: 'Query' }
  & { topic: (
    { __typename?: 'Topic' }
    & Pick<Topic, 'feed'>
  ) }
);

export type ShortsCarouselQueryVariables = Exact<{
  playlistId?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ShortsCarouselQuery = (
  { __typename?: 'Query' }
  & { allShorts: Array<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'thumbnailUrl' | 'externalId' | 'title' | 'pubDate'>
    & { newsPublisher: (
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name' | 'slug' | 'iconUrl' | 'imageUrl'>
    ) }
  )> }
);

export type VideoPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VideoPageQuery = (
  { __typename?: 'Query' }
  & { video?: Maybe<(
    { __typename?: 'Video' }
    & Pick<Video, 'description' | 'isWebViewable'>
    & VideoSummaryFragment
  )> }
);

export type AllVideosQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;


export type AllVideosQuery = (
  { __typename?: 'Query' }
  & { allVideos?: Maybe<Array<(
    { __typename?: 'Video' }
    & Pick<Video, 'id' | 'title' | 'description' | 'imageUrl'>
  )>> }
);

export type VideosIndexPageQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  languageCodes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
}>;


export type VideosIndexPageQuery = (
  { __typename?: 'Query' }
  & { allVideos?: Maybe<Array<(
    { __typename?: 'Video' }
    & VideoSummaryFragment
  )>>, featuredVideo?: Maybe<(
    { __typename?: 'Video' }
    & VideoSummaryFragment
  )> }
);

export type WhyUseLumiPageQueryVariables = Exact<{ [key: string]: never; }>;


export type WhyUseLumiPageQuery = (
  { __typename?: 'Query' }
  & { languages: Array<(
    { __typename?: 'Language' }
    & Pick<Language, 'id' | 'code' | 'name'>
    & { newsPublishers: Array<(
      { __typename?: 'NewsPublisher' }
      & Pick<NewsPublisher, 'id' | 'name' | 'slug' | 'vectorUrl' | 'imageUrl'>
    )> }
  )> }
);

export type AllCommentsQueryVariables = Exact<{
  contentType: CommentTypeEnum;
  contentId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
}>;


export type AllCommentsQuery = (
  { __typename?: 'Query' }
  & { allComments: Array<(
    { __typename?: 'Comment' }
    & CommentDetailsFragment
  )> }
);

export type AllRepliesQueryVariables = Exact<{
  commentId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['ID']>;
}>;


export type AllRepliesQuery = (
  { __typename?: 'Query' }
  & { allReplies: Array<(
    { __typename?: 'Comment' }
    & ReplyDetailsFragment
  )> }
);

export type CategoryListQueryVariables = Exact<{
  show?: Maybe<Scalars['Boolean']>;
  explore?: Maybe<Scalars['Boolean']>;
  languageCode?: Maybe<Scalars['String']>;
}>;


export type CategoryListQuery = (
  { __typename?: 'Query' }
  & { allCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & ExploreTopicSummaryFragment
  )>> }
);

export type CustomBannerQueryVariables = Exact<{
  languageCode?: Maybe<Scalars['String']>;
}>;


export type CustomBannerQuery = (
  { __typename?: 'Query' }
  & { customBanner: (
    { __typename?: 'CustomBanner' }
    & Pick<CustomBanner, 'searchBarHintText'>
  ) }
);

export type FeaturedVideoQueryVariables = Exact<{
  languageCode: Scalars['String'];
}>;


export type FeaturedVideoQuery = (
  { __typename?: 'Query' }
  & { featuredVideo?: Maybe<(
    { __typename?: 'Video' }
    & VideoSummaryFragment
  )> }
);

export type NewsListQueryVariables = Exact<{
  type: NewsQuery;
  categoryName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  languageCodes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type NewsListQuery = (
  { __typename?: 'Query' }
  & { allNews?: Maybe<Array<(
    { __typename?: 'News' }
    & NewsSummaryFragment
  )>> }
);

export type NewsStackListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  discover?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
}>;


export type NewsStackListQuery = (
  { __typename?: 'Query' }
  & { allCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & ExploreTopicSummaryFragment
  )>> }
);

export type PublisherListQueryVariables = Exact<{
  languageCodes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PublisherListQuery = (
  { __typename?: 'Query' }
  & { publishers?: Maybe<Array<(
    { __typename?: 'NewsPublisher' }
    & Pick<NewsPublisher, 'id' | 'name' | 'slug' | 'iconUrl'>
  )>> }
);

export type TopNewsStackListQueryVariables = Exact<{
  languageCode: Scalars['String'];
}>;


export type TopNewsStackListQuery = (
  { __typename?: 'Query' }
  & { allCategories?: Maybe<Array<(
    { __typename?: 'Category' }
    & { news?: Maybe<Array<(
      { __typename?: 'News' }
      & NewsSummaryFragment
    )>>, richContent?: Maybe<(
      { __typename?: 'RichContent' }
      & Pick<RichContent, 'id' | 'data' | 'updatedAt'>
    )> }
    & ExploreTopicSummaryFragment
  )>> }
);

export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on UserPublic {
  uuid
  avatar
  verified
  username
  displayName
}
    `;
export const ReplyDetailsFragmentDoc = gql`
    fragment ReplyDetails on Comment {
  id
  createdAt
  content
  likesCount
  user {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;
export const CommentDetailsFragmentDoc = gql`
    fragment CommentDetails on Comment {
  id
  createdAt
  content
  likesCount
  topReplies {
    ...ReplyDetails
  }
  commentsCount
  user {
    ...UserDetails
  }
}
    ${ReplyDetailsFragmentDoc}
${UserDetailsFragmentDoc}`;
export const AuthorDetailsFragmentDoc = gql`
    fragment AuthorDetails on Author {
  id
  name
  imageUrl
  verified
  authorType
}
    `;
export const ExploreTopicSummaryFragmentDoc = gql`
    fragment ExploreTopicSummary on Category {
  id
  key
  slug
  imageUrl
  hot
  updated
  exclusive
  exploreName
  description
  thumbnailUrl
  newsCount
  publishersCount
  shareCount
  likesCount
  commentsCount
  updatedAt
  author {
    ...AuthorDetails
  }
}
    ${AuthorDetailsFragmentDoc}`;
export const NewsPublisherDetailsFragmentDoc = gql`
    fragment NewsPublisherDetails on NewsPublisher {
  id
  name
  slug
  description
  bigImageUrl
  iconUrl
  new
  badges
  imageUrl
}
    `;
export const NewsSummaryFragmentDoc = gql`
    fragment NewsSummary on News {
  id
  imageUrl
  thumbnailUrl
  title
  description
  good
  pubDate
  newsPublisher {
    id
    name
    slug
    iconUrl
    imageUrl
  }
  shareCount
  commentsCount
}
    `;
export const VideoSummaryFragmentDoc = gql`
    fragment VideoSummary on Video {
  id
  imageUrl
  externalId
  title
  pubDate
  newsPublisher {
    id
    name
    slug
    iconUrl
    imageUrl
  }
}
    `;
export const AboutPageDocument = gql`
    query AboutPage {
  languages {
    id
    code
    name
    newsPublishers {
      ...NewsPublisherDetails
    }
  }
  publishers {
    id
    name
    slug
    vectorUrl
    imageUrl
    iconUrl
  }
}
    ${NewsPublisherDetailsFragmentDoc}`;

/**
 * __useAboutPageQuery__
 *
 * To run a query within a React component, call `useAboutPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAboutPageQuery(baseOptions?: Apollo.QueryHookOptions<AboutPageQuery, AboutPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AboutPageQuery, AboutPageQueryVariables>(AboutPageDocument, options);
      }
export function useAboutPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutPageQuery, AboutPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AboutPageQuery, AboutPageQueryVariables>(AboutPageDocument, options);
        }
export type AboutPageQueryHookResult = ReturnType<typeof useAboutPageQuery>;
export type AboutPageLazyQueryHookResult = ReturnType<typeof useAboutPageLazyQuery>;
export type AboutPageQueryResult = Apollo.QueryResult<AboutPageQuery, AboutPageQueryVariables>;
export const CovidPageDocument = gql`
    query CovidPage {
  covidStat {
    id
    newCases
    activeCases
    recovered
    deaths
    totalCases
    totalRecovered
    totalDeaths
    date
    updatedAt
    hospitalUsagePercentage
    icuUsagePercentage
    stateData {
      id
      name
      newCases
      totalCases
    }
  }
  vaccinateStat {
    id
    totalDaily
    dose1Cumul
    dose2Cumul
    dose1Percentage
    vaccinatedPercentage
    boosterCumul
    boosterDaily
    boosterPercentage
    adultVaccinatedPercentage
    totalCumul
    date
    updatedAt
    stateData {
      id
      name
      vaccinatedPercentage
    }
  }
  customBanner {
    covidTabBanner
    dailyDosesLabel
    vaccinationGoalLabel
    totalDosesLabel
    oneDoseLabel
    twoDosesLabel
    newCovidCasesLabel
    confirmedCasesLabel
  }
  covidNews: allNews(
    type: category
    categoryName: "COVID-19"
    offsetAttributes: {limit: 3}
  ) {
    ...NewsSummary
  }
  goodNews: allNews(type: goodNews, offsetAttributes: {limit: 3}) {
    ...NewsSummary
  }
}
    ${NewsSummaryFragmentDoc}`;

/**
 * __useCovidPageQuery__
 *
 * To run a query within a React component, call `useCovidPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCovidPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCovidPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCovidPageQuery(baseOptions?: Apollo.QueryHookOptions<CovidPageQuery, CovidPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CovidPageQuery, CovidPageQueryVariables>(CovidPageDocument, options);
      }
export function useCovidPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CovidPageQuery, CovidPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CovidPageQuery, CovidPageQueryVariables>(CovidPageDocument, options);
        }
export type CovidPageQueryHookResult = ReturnType<typeof useCovidPageQuery>;
export type CovidPageLazyQueryHookResult = ReturnType<typeof useCovidPageLazyQuery>;
export type CovidPageQueryResult = Apollo.QueryResult<CovidPageQuery, CovidPageQueryVariables>;
export const Ge15PageDocument = gql`
    query GE15Page($languageCode: String) {
  ge15FeaturedVideo(languageCode: $languageCode) {
    ...VideoSummary
  }
  ge15Stacks(languageCode: $languageCode) {
    id
    title
    description
    categories {
      id
      key
      slug
      imageUrl
      hot
      exploreName
      updatedAt
    }
  }
  allCategories(explore: true, languageCode: $languageCode) {
    id
    key
    slug
    imageUrl
    hot
    updated
    exploreName
    description
  }
}
    ${VideoSummaryFragmentDoc}`;

/**
 * __useGe15PageQuery__
 *
 * To run a query within a React component, call `useGe15PageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGe15PageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGe15PageQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGe15PageQuery(baseOptions?: Apollo.QueryHookOptions<Ge15PageQuery, Ge15PageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Ge15PageQuery, Ge15PageQueryVariables>(Ge15PageDocument, options);
      }
export function useGe15PageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Ge15PageQuery, Ge15PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Ge15PageQuery, Ge15PageQueryVariables>(Ge15PageDocument, options);
        }
export type Ge15PageQueryHookResult = ReturnType<typeof useGe15PageQuery>;
export type Ge15PageLazyQueryHookResult = ReturnType<typeof useGe15PageLazyQuery>;
export type Ge15PageQueryResult = Apollo.QueryResult<Ge15PageQuery, Ge15PageQueryVariables>;
export const HomePageDocument = gql`
    query HomePage($languageCode: String!) {
  allCategories(explore: true, languageCode: $languageCode) {
    ...ExploreTopicSummary
  }
  topExploreCategory: allCategories(
    explore: true
    offsetAttributes: {limit: 2}
    languageCode: $languageCode
  ) {
    ...ExploreTopicSummary
    news(offsetAttributes: {limit: 4}) {
      ...NewsSummary
    }
    richContent(languageCode: $languageCode) {
      id
      data
      updatedAt
    }
  }
  allVideos(languageCodes: [$languageCode]) {
    ...VideoSummary
  }
}
    ${ExploreTopicSummaryFragmentDoc}
${NewsSummaryFragmentDoc}
${VideoSummaryFragmentDoc}`;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useHomePageQuery(baseOptions: Apollo.QueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
      }
export function useHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
        }
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageQueryResult = Apollo.QueryResult<HomePageQuery, HomePageQueryVariables>;
export const CategoriesWithIdsDocument = gql`
    query CategoriesWithIds($ids: [ID!]!) {
  categoriesWithIds(ids: $ids) {
    id
    key
    slug
    imageUrl
    exploreName
    hot
    updated
    exclusive
    description
    author {
      ...AuthorDetails
    }
  }
}
    ${AuthorDetailsFragmentDoc}`;

/**
 * __useCategoriesWithIdsQuery__
 *
 * To run a query within a React component, call `useCategoriesWithIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesWithIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesWithIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCategoriesWithIdsQuery(baseOptions: Apollo.QueryHookOptions<CategoriesWithIdsQuery, CategoriesWithIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesWithIdsQuery, CategoriesWithIdsQueryVariables>(CategoriesWithIdsDocument, options);
      }
export function useCategoriesWithIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesWithIdsQuery, CategoriesWithIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesWithIdsQuery, CategoriesWithIdsQueryVariables>(CategoriesWithIdsDocument, options);
        }
export type CategoriesWithIdsQueryHookResult = ReturnType<typeof useCategoriesWithIdsQuery>;
export type CategoriesWithIdsLazyQueryHookResult = ReturnType<typeof useCategoriesWithIdsLazyQuery>;
export type CategoriesWithIdsQueryResult = Apollo.QueryResult<CategoriesWithIdsQuery, CategoriesWithIdsQueryVariables>;
export const NewsWithIdsDocument = gql`
    query NewsWithIds($ids: [ID!]!) {
  newsWithIds(ids: $ids) {
    id
    title
    description
    imageUrl
    good
    pubDate
    newsPublisher {
      id
      iconUrl
      imageUrl
      name
      slug
    }
  }
}
    `;

/**
 * __useNewsWithIdsQuery__
 *
 * To run a query within a React component, call `useNewsWithIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsWithIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsWithIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useNewsWithIdsQuery(baseOptions: Apollo.QueryHookOptions<NewsWithIdsQuery, NewsWithIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsWithIdsQuery, NewsWithIdsQueryVariables>(NewsWithIdsDocument, options);
      }
export function useNewsWithIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsWithIdsQuery, NewsWithIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsWithIdsQuery, NewsWithIdsQueryVariables>(NewsWithIdsDocument, options);
        }
export type NewsWithIdsQueryHookResult = ReturnType<typeof useNewsWithIdsQuery>;
export type NewsWithIdsLazyQueryHookResult = ReturnType<typeof useNewsWithIdsLazyQuery>;
export type NewsWithIdsQueryResult = Apollo.QueryResult<NewsWithIdsQuery, NewsWithIdsQueryVariables>;
export const VideosWithIdsDocument = gql`
    query VideosWithIds($ids: [ID!]!) {
  videosWithIds(ids: $ids) {
    id
    externalId
    title
    newsPublisher {
      id
      iconUrl
      name
      slug
    }
  }
}
    `;

/**
 * __useVideosWithIdsQuery__
 *
 * To run a query within a React component, call `useVideosWithIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosWithIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideosWithIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useVideosWithIdsQuery(baseOptions: Apollo.QueryHookOptions<VideosWithIdsQuery, VideosWithIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideosWithIdsQuery, VideosWithIdsQueryVariables>(VideosWithIdsDocument, options);
      }
export function useVideosWithIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideosWithIdsQuery, VideosWithIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideosWithIdsQuery, VideosWithIdsQueryVariables>(VideosWithIdsDocument, options);
        }
export type VideosWithIdsQueryHookResult = ReturnType<typeof useVideosWithIdsQuery>;
export type VideosWithIdsLazyQueryHookResult = ReturnType<typeof useVideosWithIdsLazyQuery>;
export type VideosWithIdsQueryResult = Apollo.QueryResult<VideosWithIdsQuery, VideosWithIdsQueryVariables>;
export const NewsStackPageDocument = gql`
    query NewsStackPage($id: ID!, $languageCode: String!, $limit: Int, $offset: Int, $after: ID) {
  category(id: $id, languageCode: $languageCode) {
    ...ExploreTopicSummary
    richContent(languageCode: $languageCode) {
      data
      updatedAt
    }
    news(offsetAttributes: {limit: 30}) {
      ...NewsSummary
    }
    comments(offsetAttributes: {limit: $limit, offset: $offset, after: $after}) {
      ...CommentDetails
    }
  }
}
    ${ExploreTopicSummaryFragmentDoc}
${NewsSummaryFragmentDoc}
${CommentDetailsFragmentDoc}`;

/**
 * __useNewsStackPageQuery__
 *
 * To run a query within a React component, call `useNewsStackPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsStackPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsStackPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      languageCode: // value for 'languageCode'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useNewsStackPageQuery(baseOptions: Apollo.QueryHookOptions<NewsStackPageQuery, NewsStackPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsStackPageQuery, NewsStackPageQueryVariables>(NewsStackPageDocument, options);
      }
export function useNewsStackPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsStackPageQuery, NewsStackPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsStackPageQuery, NewsStackPageQueryVariables>(NewsStackPageDocument, options);
        }
export type NewsStackPageQueryHookResult = ReturnType<typeof useNewsStackPageQuery>;
export type NewsStackPageLazyQueryHookResult = ReturnType<typeof useNewsStackPageLazyQuery>;
export type NewsStackPageQueryResult = Apollo.QueryResult<NewsStackPageQuery, NewsStackPageQueryVariables>;
export const AllNewsStackDocument = gql`
    query AllNewsStack($languageCode: String) {
  allCategories(explore: true, languageCode: $languageCode) {
    id
    slug
    key
    hot
    exploreName
    updated
    exclusive
  }
}
    `;

/**
 * __useAllNewsStackQuery__
 *
 * To run a query within a React component, call `useAllNewsStackQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNewsStackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNewsStackQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useAllNewsStackQuery(baseOptions?: Apollo.QueryHookOptions<AllNewsStackQuery, AllNewsStackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllNewsStackQuery, AllNewsStackQueryVariables>(AllNewsStackDocument, options);
      }
export function useAllNewsStackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllNewsStackQuery, AllNewsStackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllNewsStackQuery, AllNewsStackQueryVariables>(AllNewsStackDocument, options);
        }
export type AllNewsStackQueryHookResult = ReturnType<typeof useAllNewsStackQuery>;
export type AllNewsStackLazyQueryHookResult = ReturnType<typeof useAllNewsStackLazyQuery>;
export type AllNewsStackQueryResult = Apollo.QueryResult<AllNewsStackQuery, AllNewsStackQueryVariables>;
export const NewsPageDocument = gql`
    query NewsPage($id: ID!, $languageCode: String, $limit: Int, $offset: Int, $after: ID) {
  news(id: $id, languageCode: $languageCode) {
    id
    title
    description
    content
    pubDate
    thumbnailUrl
    link
    imageUrl
    link
    newsPublisher {
      id
      name
      slug
      imageUrl
      language {
        code
      }
    }
    isUnderNewsStack
    nextNews(languageCode: $languageCode) {
      ...NewsSummary
    }
    topics {
      id
      key
      name
      emoji
    }
    commentsCount
    shareCount
    comments(offsetAttributes: {limit: $limit, offset: $offset, after: $after}) {
      ...CommentDetails
    }
  }
}
    ${NewsSummaryFragmentDoc}
${CommentDetailsFragmentDoc}`;

/**
 * __useNewsPageQuery__
 *
 * To run a query within a React component, call `useNewsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      languageCode: // value for 'languageCode'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useNewsPageQuery(baseOptions: Apollo.QueryHookOptions<NewsPageQuery, NewsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsPageQuery, NewsPageQueryVariables>(NewsPageDocument, options);
      }
export function useNewsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsPageQuery, NewsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsPageQuery, NewsPageQueryVariables>(NewsPageDocument, options);
        }
export type NewsPageQueryHookResult = ReturnType<typeof useNewsPageQuery>;
export type NewsPageLazyQueryHookResult = ReturnType<typeof useNewsPageLazyQuery>;
export type NewsPageQueryResult = Apollo.QueryResult<NewsPageQuery, NewsPageQueryVariables>;
export const AllNewsDocument = gql`
    query AllNews($limit: Int) {
  allNews(type: latest, offsetAttributes: {limit: $limit}) {
    id
    title
    imageUrl
    pubDate
    newsPublisher {
      id
      name
      language {
        id
        code
      }
    }
  }
}
    `;

/**
 * __useAllNewsQuery__
 *
 * To run a query within a React component, call `useAllNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllNewsQuery(baseOptions?: Apollo.QueryHookOptions<AllNewsQuery, AllNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllNewsQuery, AllNewsQueryVariables>(AllNewsDocument, options);
      }
export function useAllNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllNewsQuery, AllNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllNewsQuery, AllNewsQueryVariables>(AllNewsDocument, options);
        }
export type AllNewsQueryHookResult = ReturnType<typeof useAllNewsQuery>;
export type AllNewsLazyQueryHookResult = ReturnType<typeof useAllNewsLazyQuery>;
export type AllNewsQueryResult = Apollo.QueryResult<AllNewsQuery, AllNewsQueryVariables>;
export const NewsLandingPageDocument = gql`
    query NewsLandingPage($languageCode: String!) {
  allCategories(
    explore: false
    offsetAttributes: {limit: 30}
    languageCode: $languageCode
  ) {
    id
    key
    name
    slug
    imageUrl
    hot
    exploreName
    emoji
  }
  topExploreCategory: allCategories(
    explore: true
    offsetAttributes: {limit: 1}
    languageCode: $languageCode
  ) {
    ...ExploreTopicSummary
    news(offsetAttributes: {limit: 4}) {
      ...NewsSummary
    }
  }
}
    ${ExploreTopicSummaryFragmentDoc}
${NewsSummaryFragmentDoc}`;

/**
 * __useNewsLandingPageQuery__
 *
 * To run a query within a React component, call `useNewsLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsLandingPageQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useNewsLandingPageQuery(baseOptions: Apollo.QueryHookOptions<NewsLandingPageQuery, NewsLandingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsLandingPageQuery, NewsLandingPageQueryVariables>(NewsLandingPageDocument, options);
      }
export function useNewsLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsLandingPageQuery, NewsLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsLandingPageQuery, NewsLandingPageQueryVariables>(NewsLandingPageDocument, options);
        }
export type NewsLandingPageQueryHookResult = ReturnType<typeof useNewsLandingPageQuery>;
export type NewsLandingPageLazyQueryHookResult = ReturnType<typeof useNewsLandingPageLazyQuery>;
export type NewsLandingPageQueryResult = Apollo.QueryResult<NewsLandingPageQuery, NewsLandingPageQueryVariables>;
export const Prn2023PageDocument = gql`
    query Prn2023Page($id: ID!, $languageCode: String!) {
  topicStack(id: $id, languageCode: $languageCode) {
    title
    description
    categories {
      ...ExploreTopicSummary
      richContent(languageCode: $languageCode) {
        id
        data
        updatedAt
      }
    }
  }
}
    ${ExploreTopicSummaryFragmentDoc}`;

/**
 * __usePrn2023PageQuery__
 *
 * To run a query within a React component, call `usePrn2023PageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrn2023PageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrn2023PageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function usePrn2023PageQuery(baseOptions: Apollo.QueryHookOptions<Prn2023PageQuery, Prn2023PageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Prn2023PageQuery, Prn2023PageQueryVariables>(Prn2023PageDocument, options);
      }
export function usePrn2023PageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Prn2023PageQuery, Prn2023PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Prn2023PageQuery, Prn2023PageQueryVariables>(Prn2023PageDocument, options);
        }
export type Prn2023PageQueryHookResult = ReturnType<typeof usePrn2023PageQuery>;
export type Prn2023PageLazyQueryHookResult = ReturnType<typeof usePrn2023PageLazyQuery>;
export type Prn2023PageQueryResult = Apollo.QueryResult<Prn2023PageQuery, Prn2023PageQueryVariables>;
export const PublisherPageDocument = gql`
    query PublisherPage($id: ID!) {
  publisher(id: $id) {
    ...NewsPublisherDetails
  }
}
    ${NewsPublisherDetailsFragmentDoc}`;

/**
 * __usePublisherPageQuery__
 *
 * To run a query within a React component, call `usePublisherPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublisherPageQuery(baseOptions: Apollo.QueryHookOptions<PublisherPageQuery, PublisherPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherPageQuery, PublisherPageQueryVariables>(PublisherPageDocument, options);
      }
export function usePublisherPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherPageQuery, PublisherPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherPageQuery, PublisherPageQueryVariables>(PublisherPageDocument, options);
        }
export type PublisherPageQueryHookResult = ReturnType<typeof usePublisherPageQuery>;
export type PublisherPageLazyQueryHookResult = ReturnType<typeof usePublisherPageLazyQuery>;
export type PublisherPageQueryResult = Apollo.QueryResult<PublisherPageQuery, PublisherPageQueryVariables>;
export const PublisherNewsDocument = gql`
    query PublisherNews($limit: Int, $offset: Int, $id: ID!) {
  publisher(id: $id) {
    id
    news(offsetAttributes: {limit: $limit, offset: $offset}) {
      ...NewsSummary
    }
    videos(offsetAttributes: {limit: $limit, offset: $offset}) {
      ...VideoSummary
    }
  }
}
    ${NewsSummaryFragmentDoc}
${VideoSummaryFragmentDoc}`;

/**
 * __usePublisherNewsQuery__
 *
 * To run a query within a React component, call `usePublisherNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherNewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublisherNewsQuery(baseOptions: Apollo.QueryHookOptions<PublisherNewsQuery, PublisherNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherNewsQuery, PublisherNewsQueryVariables>(PublisherNewsDocument, options);
      }
export function usePublisherNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherNewsQuery, PublisherNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherNewsQuery, PublisherNewsQueryVariables>(PublisherNewsDocument, options);
        }
export type PublisherNewsQueryHookResult = ReturnType<typeof usePublisherNewsQuery>;
export type PublisherNewsLazyQueryHookResult = ReturnType<typeof usePublisherNewsLazyQuery>;
export type PublisherNewsQueryResult = Apollo.QueryResult<PublisherNewsQuery, PublisherNewsQueryVariables>;
export const PublishersPageDocument = gql`
    query PublishersPage {
  publishers {
    id
    name
    slug
  }
}
    `;

/**
 * __usePublishersPageQuery__
 *
 * To run a query within a React component, call `usePublishersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublishersPageQuery(baseOptions?: Apollo.QueryHookOptions<PublishersPageQuery, PublishersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishersPageQuery, PublishersPageQueryVariables>(PublishersPageDocument, options);
      }
export function usePublishersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishersPageQuery, PublishersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishersPageQuery, PublishersPageQueryVariables>(PublishersPageDocument, options);
        }
export type PublishersPageQueryHookResult = ReturnType<typeof usePublishersPageQuery>;
export type PublishersPageLazyQueryHookResult = ReturnType<typeof usePublishersPageLazyQuery>;
export type PublishersPageQueryResult = Apollo.QueryResult<PublishersPageQuery, PublishersPageQueryVariables>;
export const PublishersLandingPageDocument = gql`
    query PublishersLandingPage {
  languages {
    id
    code
    name
    newsPublishers {
      ...NewsPublisherDetails
    }
  }
}
    ${NewsPublisherDetailsFragmentDoc}`;

/**
 * __usePublishersLandingPageQuery__
 *
 * To run a query within a React component, call `usePublishersLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishersLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishersLandingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublishersLandingPageQuery(baseOptions?: Apollo.QueryHookOptions<PublishersLandingPageQuery, PublishersLandingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishersLandingPageQuery, PublishersLandingPageQueryVariables>(PublishersLandingPageDocument, options);
      }
export function usePublishersLandingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishersLandingPageQuery, PublishersLandingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishersLandingPageQuery, PublishersLandingPageQueryVariables>(PublishersLandingPageDocument, options);
        }
export type PublishersLandingPageQueryHookResult = ReturnType<typeof usePublishersLandingPageQuery>;
export type PublishersLandingPageLazyQueryHookResult = ReturnType<typeof usePublishersLandingPageLazyQuery>;
export type PublishersLandingPageQueryResult = Apollo.QueryResult<PublishersLandingPageQuery, PublishersLandingPageQueryVariables>;
export const SearchDocument = gql`
    query Search($limit: Int, $offset: Int, $search: String!, $languageCode: String) {
  allNews(
    offsetAttributes: {limit: $limit, offset: $offset}
    search: $search
    type: search
  ) {
    ...NewsSummary
  }
  allVideos(offsetAttributes: {limit: $limit, offset: $offset}, search: $search) {
    ...VideoSummary
  }
  publishers(search: $search) {
    ...NewsPublisherDetails
  }
  allCategories(
    offsetAttributes: {limit: $limit, offset: $offset}
    search: $search
    explore: true
    languageCode: $languageCode
  ) {
    ...ExploreTopicSummary
  }
  allShorts(offsetAttributes: {limit: $limit, offset: $offset}, search: $search) {
    ...VideoSummary
  }
  searchCount(search: $search) {
    news
    video
    short
  }
}
    ${NewsSummaryFragmentDoc}
${VideoSummaryFragmentDoc}
${NewsPublisherDetailsFragmentDoc}
${ExploreTopicSummaryFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const ShortsDocument = gql`
    query Shorts($limit: Int, $offset: Int, $after: ID!) {
  video(id: $after) {
    ...VideoSummary
  }
  allShorts(offsetAttributes: {limit: $limit, offset: $offset, after: $after}) {
    ...VideoSummary
  }
}
    ${VideoSummaryFragmentDoc}`;

/**
 * __useShortsQuery__
 *
 * To run a query within a React component, call `useShortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useShortsQuery(baseOptions: Apollo.QueryHookOptions<ShortsQuery, ShortsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortsQuery, ShortsQueryVariables>(ShortsDocument, options);
      }
export function useShortsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortsQuery, ShortsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortsQuery, ShortsQueryVariables>(ShortsDocument, options);
        }
export type ShortsQueryHookResult = ReturnType<typeof useShortsQuery>;
export type ShortsLazyQueryHookResult = ReturnType<typeof useShortsLazyQuery>;
export type ShortsQueryResult = Apollo.QueryResult<ShortsQuery, ShortsQueryVariables>;
export const TopicFeedDocument = gql`
    query TopicFeed($slug: ID!, $languageCode: String) {
  topic(id: $slug, languageCode: $languageCode) {
    id
    feed
    blocks
  }
  category(id: $slug, languageCode: $languageCode) {
    id
    name
    exploreName
    description
  }
}
    `;

/**
 * __useTopicFeedQuery__
 *
 * To run a query within a React component, call `useTopicFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicFeedQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useTopicFeedQuery(baseOptions: Apollo.QueryHookOptions<TopicFeedQuery, TopicFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicFeedQuery, TopicFeedQueryVariables>(TopicFeedDocument, options);
      }
export function useTopicFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicFeedQuery, TopicFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicFeedQuery, TopicFeedQueryVariables>(TopicFeedDocument, options);
        }
export type TopicFeedQueryHookResult = ReturnType<typeof useTopicFeedQuery>;
export type TopicFeedLazyQueryHookResult = ReturnType<typeof useTopicFeedLazyQuery>;
export type TopicFeedQueryResult = Apollo.QueryResult<TopicFeedQuery, TopicFeedQueryVariables>;
export const TopicFeedPaginationDocument = gql`
    query TopicFeedPagination($slug: ID!, $page: Int, $languageCode: String) {
  topic(id: $slug, languageCode: $languageCode) {
    feed(page: $page)
  }
}
    `;

/**
 * __useTopicFeedPaginationQuery__
 *
 * To run a query within a React component, call `useTopicFeedPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicFeedPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicFeedPaginationQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      page: // value for 'page'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useTopicFeedPaginationQuery(baseOptions: Apollo.QueryHookOptions<TopicFeedPaginationQuery, TopicFeedPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicFeedPaginationQuery, TopicFeedPaginationQueryVariables>(TopicFeedPaginationDocument, options);
      }
export function useTopicFeedPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicFeedPaginationQuery, TopicFeedPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicFeedPaginationQuery, TopicFeedPaginationQueryVariables>(TopicFeedPaginationDocument, options);
        }
export type TopicFeedPaginationQueryHookResult = ReturnType<typeof useTopicFeedPaginationQuery>;
export type TopicFeedPaginationLazyQueryHookResult = ReturnType<typeof useTopicFeedPaginationLazyQuery>;
export type TopicFeedPaginationQueryResult = Apollo.QueryResult<TopicFeedPaginationQuery, TopicFeedPaginationQueryVariables>;
export const ShortsCarouselDocument = gql`
    query ShortsCarousel($playlistId: String, $languageCode: String, $limit: Int, $offset: Int) {
  allShorts(
    playlistId: $playlistId
    languageCode: $languageCode
    offsetAttributes: {limit: $limit, offset: $offset}
  ) {
    id
    thumbnailUrl
    externalId
    title
    pubDate
    newsPublisher {
      id
      name
      slug
      iconUrl
      imageUrl
    }
  }
}
    `;

/**
 * __useShortsCarouselQuery__
 *
 * To run a query within a React component, call `useShortsCarouselQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortsCarouselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortsCarouselQuery({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      languageCode: // value for 'languageCode'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useShortsCarouselQuery(baseOptions?: Apollo.QueryHookOptions<ShortsCarouselQuery, ShortsCarouselQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortsCarouselQuery, ShortsCarouselQueryVariables>(ShortsCarouselDocument, options);
      }
export function useShortsCarouselLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortsCarouselQuery, ShortsCarouselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortsCarouselQuery, ShortsCarouselQueryVariables>(ShortsCarouselDocument, options);
        }
export type ShortsCarouselQueryHookResult = ReturnType<typeof useShortsCarouselQuery>;
export type ShortsCarouselLazyQueryHookResult = ReturnType<typeof useShortsCarouselLazyQuery>;
export type ShortsCarouselQueryResult = Apollo.QueryResult<ShortsCarouselQuery, ShortsCarouselQueryVariables>;
export const VideoPageDocument = gql`
    query VideoPage($id: ID!) {
  video(id: $id) {
    ...VideoSummary
    description
    isWebViewable
  }
}
    ${VideoSummaryFragmentDoc}`;

/**
 * __useVideoPageQuery__
 *
 * To run a query within a React component, call `useVideoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoPageQuery(baseOptions: Apollo.QueryHookOptions<VideoPageQuery, VideoPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoPageQuery, VideoPageQueryVariables>(VideoPageDocument, options);
      }
export function useVideoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoPageQuery, VideoPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoPageQuery, VideoPageQueryVariables>(VideoPageDocument, options);
        }
export type VideoPageQueryHookResult = ReturnType<typeof useVideoPageQuery>;
export type VideoPageLazyQueryHookResult = ReturnType<typeof useVideoPageLazyQuery>;
export type VideoPageQueryResult = Apollo.QueryResult<VideoPageQuery, VideoPageQueryVariables>;
export const AllVideosDocument = gql`
    query AllVideos($limit: Int) {
  allVideos(offsetAttributes: {limit: $limit}) {
    id
    title
    description
    imageUrl
  }
}
    `;

/**
 * __useAllVideosQuery__
 *
 * To run a query within a React component, call `useAllVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVideosQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllVideosQuery(baseOptions?: Apollo.QueryHookOptions<AllVideosQuery, AllVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllVideosQuery, AllVideosQueryVariables>(AllVideosDocument, options);
      }
export function useAllVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllVideosQuery, AllVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllVideosQuery, AllVideosQueryVariables>(AllVideosDocument, options);
        }
export type AllVideosQueryHookResult = ReturnType<typeof useAllVideosQuery>;
export type AllVideosLazyQueryHookResult = ReturnType<typeof useAllVideosLazyQuery>;
export type AllVideosQueryResult = Apollo.QueryResult<AllVideosQuery, AllVideosQueryVariables>;
export const VideosIndexPageDocument = gql`
    query VideosIndexPage($limit: Int, $offset: Int, $languageCodes: [String!], $languageCode: String, $categoryName: String) {
  allVideos(
    categoryName: $categoryName
    offsetAttributes: {limit: $limit, offset: $offset}
    languageCodes: $languageCodes
  ) {
    ...VideoSummary
  }
  featuredVideo(languageCode: $languageCode) {
    ...VideoSummary
  }
}
    ${VideoSummaryFragmentDoc}`;

/**
 * __useVideosIndexPageQuery__
 *
 * To run a query within a React component, call `useVideosIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideosIndexPageQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      languageCodes: // value for 'languageCodes'
 *      languageCode: // value for 'languageCode'
 *      categoryName: // value for 'categoryName'
 *   },
 * });
 */
export function useVideosIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<VideosIndexPageQuery, VideosIndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideosIndexPageQuery, VideosIndexPageQueryVariables>(VideosIndexPageDocument, options);
      }
export function useVideosIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideosIndexPageQuery, VideosIndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideosIndexPageQuery, VideosIndexPageQueryVariables>(VideosIndexPageDocument, options);
        }
export type VideosIndexPageQueryHookResult = ReturnType<typeof useVideosIndexPageQuery>;
export type VideosIndexPageLazyQueryHookResult = ReturnType<typeof useVideosIndexPageLazyQuery>;
export type VideosIndexPageQueryResult = Apollo.QueryResult<VideosIndexPageQuery, VideosIndexPageQueryVariables>;
export const WhyUseLumiPageDocument = gql`
    query WhyUseLumiPage {
  languages {
    id
    code
    name
    newsPublishers {
      id
      name
      slug
      vectorUrl
      imageUrl
    }
  }
}
    `;

/**
 * __useWhyUseLumiPageQuery__
 *
 * To run a query within a React component, call `useWhyUseLumiPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhyUseLumiPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhyUseLumiPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhyUseLumiPageQuery(baseOptions?: Apollo.QueryHookOptions<WhyUseLumiPageQuery, WhyUseLumiPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhyUseLumiPageQuery, WhyUseLumiPageQueryVariables>(WhyUseLumiPageDocument, options);
      }
export function useWhyUseLumiPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhyUseLumiPageQuery, WhyUseLumiPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhyUseLumiPageQuery, WhyUseLumiPageQueryVariables>(WhyUseLumiPageDocument, options);
        }
export type WhyUseLumiPageQueryHookResult = ReturnType<typeof useWhyUseLumiPageQuery>;
export type WhyUseLumiPageLazyQueryHookResult = ReturnType<typeof useWhyUseLumiPageLazyQuery>;
export type WhyUseLumiPageQueryResult = Apollo.QueryResult<WhyUseLumiPageQuery, WhyUseLumiPageQueryVariables>;
export const AllCommentsDocument = gql`
    query AllComments($contentType: CommentTypeEnum!, $contentId: ID!, $limit: Int, $offset: Int, $after: ID) {
  allComments(
    contentType: $contentType
    contentId: $contentId
    offsetAttributes: {limit: $limit, offset: $offset, after: $after}
  ) {
    ...CommentDetails
  }
}
    ${CommentDetailsFragmentDoc}`;

/**
 * __useAllCommentsQuery__
 *
 * To run a query within a React component, call `useAllCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommentsQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      contentId: // value for 'contentId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAllCommentsQuery(baseOptions: Apollo.QueryHookOptions<AllCommentsQuery, AllCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCommentsQuery, AllCommentsQueryVariables>(AllCommentsDocument, options);
      }
export function useAllCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCommentsQuery, AllCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCommentsQuery, AllCommentsQueryVariables>(AllCommentsDocument, options);
        }
export type AllCommentsQueryHookResult = ReturnType<typeof useAllCommentsQuery>;
export type AllCommentsLazyQueryHookResult = ReturnType<typeof useAllCommentsLazyQuery>;
export type AllCommentsQueryResult = Apollo.QueryResult<AllCommentsQuery, AllCommentsQueryVariables>;
export const AllRepliesDocument = gql`
    query AllReplies($commentId: ID!, $limit: Int, $offset: Int, $after: ID) {
  allReplies(
    commentId: $commentId
    offsetAttributes: {limit: $limit, offset: $offset, after: $after}
  ) {
    ...ReplyDetails
  }
}
    ${ReplyDetailsFragmentDoc}`;

/**
 * __useAllRepliesQuery__
 *
 * To run a query within a React component, call `useAllRepliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRepliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRepliesQuery({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAllRepliesQuery(baseOptions: Apollo.QueryHookOptions<AllRepliesQuery, AllRepliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllRepliesQuery, AllRepliesQueryVariables>(AllRepliesDocument, options);
      }
export function useAllRepliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllRepliesQuery, AllRepliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllRepliesQuery, AllRepliesQueryVariables>(AllRepliesDocument, options);
        }
export type AllRepliesQueryHookResult = ReturnType<typeof useAllRepliesQuery>;
export type AllRepliesLazyQueryHookResult = ReturnType<typeof useAllRepliesLazyQuery>;
export type AllRepliesQueryResult = Apollo.QueryResult<AllRepliesQuery, AllRepliesQueryVariables>;
export const CategoryListDocument = gql`
    query CategoryList($show: Boolean, $explore: Boolean, $languageCode: String) {
  allCategories(show: $show, explore: $explore, languageCode: $languageCode) {
    ...ExploreTopicSummary
  }
}
    ${ExploreTopicSummaryFragmentDoc}`;

/**
 * __useCategoryListQuery__
 *
 * To run a query within a React component, call `useCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryListQuery({
 *   variables: {
 *      show: // value for 'show'
 *      explore: // value for 'explore'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, options);
      }
export function useCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, options);
        }
export type CategoryListQueryHookResult = ReturnType<typeof useCategoryListQuery>;
export type CategoryListLazyQueryHookResult = ReturnType<typeof useCategoryListLazyQuery>;
export type CategoryListQueryResult = Apollo.QueryResult<CategoryListQuery, CategoryListQueryVariables>;
export const CustomBannerDocument = gql`
    query customBanner($languageCode: String) {
  customBanner(languageCode: $languageCode) {
    searchBarHintText
  }
}
    `;

/**
 * __useCustomBannerQuery__
 *
 * To run a query within a React component, call `useCustomBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomBannerQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useCustomBannerQuery(baseOptions?: Apollo.QueryHookOptions<CustomBannerQuery, CustomBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomBannerQuery, CustomBannerQueryVariables>(CustomBannerDocument, options);
      }
export function useCustomBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomBannerQuery, CustomBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomBannerQuery, CustomBannerQueryVariables>(CustomBannerDocument, options);
        }
export type CustomBannerQueryHookResult = ReturnType<typeof useCustomBannerQuery>;
export type CustomBannerLazyQueryHookResult = ReturnType<typeof useCustomBannerLazyQuery>;
export type CustomBannerQueryResult = Apollo.QueryResult<CustomBannerQuery, CustomBannerQueryVariables>;
export const FeaturedVideoDocument = gql`
    query FeaturedVideo($languageCode: String!) {
  featuredVideo(languageCode: $languageCode) {
    ...VideoSummary
  }
}
    ${VideoSummaryFragmentDoc}`;

/**
 * __useFeaturedVideoQuery__
 *
 * To run a query within a React component, call `useFeaturedVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedVideoQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useFeaturedVideoQuery(baseOptions: Apollo.QueryHookOptions<FeaturedVideoQuery, FeaturedVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturedVideoQuery, FeaturedVideoQueryVariables>(FeaturedVideoDocument, options);
      }
export function useFeaturedVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturedVideoQuery, FeaturedVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturedVideoQuery, FeaturedVideoQueryVariables>(FeaturedVideoDocument, options);
        }
export type FeaturedVideoQueryHookResult = ReturnType<typeof useFeaturedVideoQuery>;
export type FeaturedVideoLazyQueryHookResult = ReturnType<typeof useFeaturedVideoLazyQuery>;
export type FeaturedVideoQueryResult = Apollo.QueryResult<FeaturedVideoQuery, FeaturedVideoQueryVariables>;
export const NewsListDocument = gql`
    query NewsList($type: NewsQuery!, $categoryName: String, $limit: Int, $offset: Int, $languageCodes: [String!]) {
  allNews(
    type: $type
    categoryName: $categoryName
    offsetAttributes: {limit: $limit, offset: $offset}
    languageCodes: $languageCodes
  ) {
    ...NewsSummary
  }
}
    ${NewsSummaryFragmentDoc}`;

/**
 * __useNewsListQuery__
 *
 * To run a query within a React component, call `useNewsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsListQuery({
 *   variables: {
 *      type: // value for 'type'
 *      categoryName: // value for 'categoryName'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      languageCodes: // value for 'languageCodes'
 *   },
 * });
 */
export function useNewsListQuery(baseOptions: Apollo.QueryHookOptions<NewsListQuery, NewsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsListQuery, NewsListQueryVariables>(NewsListDocument, options);
      }
export function useNewsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsListQuery, NewsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsListQuery, NewsListQueryVariables>(NewsListDocument, options);
        }
export type NewsListQueryHookResult = ReturnType<typeof useNewsListQuery>;
export type NewsListLazyQueryHookResult = ReturnType<typeof useNewsListLazyQuery>;
export type NewsListQueryResult = Apollo.QueryResult<NewsListQuery, NewsListQueryVariables>;
export const NewsStackListDocument = gql`
    query newsStackList($limit: Int, $offset: Int, $discover: String, $languageCode: String) {
  allCategories(
    offsetAttributes: {limit: $limit, offset: $offset}
    discover: $discover
    languageCode: $languageCode
  ) {
    ...ExploreTopicSummary
  }
}
    ${ExploreTopicSummaryFragmentDoc}`;

/**
 * __useNewsStackListQuery__
 *
 * To run a query within a React component, call `useNewsStackListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsStackListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsStackListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      discover: // value for 'discover'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useNewsStackListQuery(baseOptions?: Apollo.QueryHookOptions<NewsStackListQuery, NewsStackListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsStackListQuery, NewsStackListQueryVariables>(NewsStackListDocument, options);
      }
export function useNewsStackListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsStackListQuery, NewsStackListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsStackListQuery, NewsStackListQueryVariables>(NewsStackListDocument, options);
        }
export type NewsStackListQueryHookResult = ReturnType<typeof useNewsStackListQuery>;
export type NewsStackListLazyQueryHookResult = ReturnType<typeof useNewsStackListLazyQuery>;
export type NewsStackListQueryResult = Apollo.QueryResult<NewsStackListQuery, NewsStackListQueryVariables>;
export const PublisherListDocument = gql`
    query PublisherList($languageCodes: [String!]) {
  publishers(languageCodes: $languageCodes) {
    id
    name
    slug
    iconUrl
  }
}
    `;

/**
 * __usePublisherListQuery__
 *
 * To run a query within a React component, call `usePublisherListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherListQuery({
 *   variables: {
 *      languageCodes: // value for 'languageCodes'
 *   },
 * });
 */
export function usePublisherListQuery(baseOptions?: Apollo.QueryHookOptions<PublisherListQuery, PublisherListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherListQuery, PublisherListQueryVariables>(PublisherListDocument, options);
      }
export function usePublisherListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherListQuery, PublisherListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherListQuery, PublisherListQueryVariables>(PublisherListDocument, options);
        }
export type PublisherListQueryHookResult = ReturnType<typeof usePublisherListQuery>;
export type PublisherListLazyQueryHookResult = ReturnType<typeof usePublisherListLazyQuery>;
export type PublisherListQueryResult = Apollo.QueryResult<PublisherListQuery, PublisherListQueryVariables>;
export const TopNewsStackListDocument = gql`
    query topNewsStackList($languageCode: String!) {
  allCategories(
    explore: true
    offsetAttributes: {limit: 1}
    languageCode: $languageCode
  ) {
    ...ExploreTopicSummary
    news(offsetAttributes: {limit: 4}) {
      ...NewsSummary
    }
    richContent(languageCode: $languageCode) {
      id
      data
      updatedAt
    }
  }
}
    ${ExploreTopicSummaryFragmentDoc}
${NewsSummaryFragmentDoc}`;

/**
 * __useTopNewsStackListQuery__
 *
 * To run a query within a React component, call `useTopNewsStackListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopNewsStackListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopNewsStackListQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useTopNewsStackListQuery(baseOptions: Apollo.QueryHookOptions<TopNewsStackListQuery, TopNewsStackListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopNewsStackListQuery, TopNewsStackListQueryVariables>(TopNewsStackListDocument, options);
      }
export function useTopNewsStackListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopNewsStackListQuery, TopNewsStackListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopNewsStackListQuery, TopNewsStackListQueryVariables>(TopNewsStackListDocument, options);
        }
export type TopNewsStackListQueryHookResult = ReturnType<typeof useTopNewsStackListQuery>;
export type TopNewsStackListLazyQueryHookResult = ReturnType<typeof useTopNewsStackListLazyQuery>;
export type TopNewsStackListQueryResult = Apollo.QueryResult<TopNewsStackListQuery, TopNewsStackListQueryVariables>;